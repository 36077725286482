import { Container, Nav, NavDropdown, NavLink, Navbar } from "react-bootstrap";
import './Header.css';
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import JamContext from "./JamContext";
import { useContext } from "react";
import { logout } from "../../Redux/Reducers/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import ClientLink from "./ClientLink";
import { useClientIdNavigate } from "../../Router";
import { useTranslation } from "react-i18next";
import { UserRoles } from "../../Business/dto/UserDetails";

export const Header = () => {
    const { t } = useTranslation();
    const navigate = useClientIdNavigate();
    const dispatch = useAppDispatch();
    const locale = useAppSelector(state => state.locale.locale);
    const context = useContext(JamContext);
    const userDetails = useAppSelector(state => state.user.details);

    const changeLanguage = (lang: string) => {
        context.handleChange({ target: { value: lang } });
    }

    const onLogoutClick = () => {
        dispatch(logout());
    }

    const onUserListLinkClick = () => {
        navigate("/userList")
    }

    const onHistoryLinkClick = () => {
        navigate("/history")
    }

    return (
        <Navbar className="jamNavBar">
            <Container className="appBaseContainer">
                <Navbar.Brand>
                    <ClientLink to="/selectBrief">
                        <img src="/img/JAM_Logo White.svg" height="30" alt="JAM LOGO" />
                    </ClientLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
                    <Nav>
                        { (userDetails && userDetails.roles.includes(UserRoles.ADMIN)) && <Nav.Link onClick={onUserListLinkClick}>{t("txt_user")}s</Nav.Link>}
                        {userDetails && <NavLink onClick={onHistoryLinkClick}>{t("txt_history")}</NavLink> }
                        <NavDropdown title={locale}>
                            <NavDropdown.Item onClick={() => changeLanguage("fr")}>Fr</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLanguage("en")}>En</NavDropdown.Item>
                        </NavDropdown>
                        {userDetails &&
                            <>
                                <NavLink onClick={onLogoutClick}><FontAwesomeIcon icon={faArrowRightFromBracket} /> {t("logout")}</NavLink>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}