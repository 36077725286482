/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useAppDispatch, useAppSelector } from "../Redux/hooks"
import { BriefConfig, SectionConfig } from "../Business/dto/ClientConfig";
import { SectionBuilder } from "../Components/Sections/SectionBuilder";
import { Button, Card, Col, Row } from "react-bootstrap";
import { SectionTimeLine } from "../Components/Sections/SectionTimeLine";
import { ConvertInputDataToBriefDTO } from "../Business/utils/BriefUtils";
import './Brief.css';
import { GetBriefFromExternalSource, resetBriefFromExternalSource, resetInputData, resetRetrievedData } from "../Redux/Reducers/briefSlice";
import { useLocation } from "react-router-dom";
import { Page } from "./Page";
import { useClientIdNavigate } from "../Router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";

export const Brief = () => {
    const { t } = useTranslation();
    const navigate = useClientIdNavigate();
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const [sectionNames, setSectionNames] = React.useState<string[]>([]);
    const [sectionValidated, setSectionValidated] = React.useState<boolean[]>([]);
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [briefConfig, setBriefConfig] = React.useState<BriefConfig>();
    const clientConfig = useAppSelector(state => state.clientConfig.config);
    const userDetails = useAppSelector(state => state.user.details);
    const inputData = useAppSelector(state => state.brief.inputData);
    const [selectedBrief, setSelectedBrief] = React.useState<string>(state ? state.selectedBrief : "");
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const [briefLoading, setBriefLoading] = React.useState<boolean>(false);
    const briefFromExternalSource = useAppSelector(state => state.brief.briefFromExternalSource);

    // Used for brief editing from MP
    React.useEffect(() => {
        if (state && state.from && state.from.search) {
            setIsEditMode(true);
            setBriefLoading(true);
            const briefConfigToUse = clientConfig?.briefConfigs.find(briefConfig => briefConfig.default);

            if (briefConfigToUse)
                setSelectedBrief(briefConfigToUse.name);

            // Get briefId from URL
            const briefId = state.from.search.split("briefId=")[1];
            // Get to the next "&" character or to the end if not present
            const nextAmpersand = briefId.indexOf("&");
            const briefIdValue = briefId.substring(0, nextAmpersand === -1 ? briefId.length : nextAmpersand);

            // Load brief from backend
            dispatch((GetBriefFromExternalSource(briefIdValue)));
        }
    }, [state])

    React.useEffect(() => {
        if (isEditMode && briefFromExternalSource) {
            console.log("briefFromExternalSource", briefFromExternalSource);
            //const inputDataFromBriefDTO = ConvertBriefDTOToInputData(briefFromExternalSource);

            //dispatch(loadInputData(inputDataFromBriefDTO))

            setBriefLoading(false);
        }
    }, [isEditMode, briefFromExternalSource])

    const onChangeSection = (sectionIdx: number) => {
        if (sectionIdx >= 0 && sectionIdx < sectionNames.length) {
            setActiveStep(sectionIdx);
        }
        else {
            if (sectionValidated.every(value => value === true))
                onBriefDone();
            else
                toast.error(t("txt_please_input_all_fields"));
        }
    }

    const onBriefDone = () => {
        if (clientConfig) {
            const allocDto = ConvertInputDataToBriefDTO(inputData, clientConfig.systemId);
            const briefDto = ConvertInputDataToBriefDTO(inputData, clientConfig.systemId, true);
            console.log("allocDto", allocDto);
            console.log("briefDto", briefDto);

            // Redirect to loading page
            navigate("/awaitResults", { state: { brief: allocDto, jamDto: briefDto, selectedBrief: selectedBrief } });
        }
    }

    React.useEffect(() => {
        dispatch(resetInputData());
        dispatch(resetRetrievedData());
        dispatch(resetBriefFromExternalSource());
    }, []);

    React.useEffect(() => {
        console.log(sectionValidated)
    }, [sectionValidated])

    React.useEffect(() => {
        if (clientConfig && userDetails && selectedBrief !== "") {
            userDetails.briefsByClient[0].briefs.forEach(brief => {
                if (brief === selectedBrief) {
                    const briefConfig = clientConfig.briefConfigs.find(briefConfig => briefConfig.name === brief);

                    if (briefConfig)
                        setBriefConfig(briefConfig);
                }
            });
        }
    }, [clientConfig, userDetails, selectedBrief]);

    React.useEffect(() => {
        if (briefConfig) {
            const tmpSectionNames: string[] = [];

            briefConfig.brief.sections.forEach((section) => {
                const sectionComponent = SectionBuilder.getInstance().getSection(section.key);

                if (sectionComponent) {
                    tmpSectionNames.push(sectionComponent.Name);
                }
            });
            setSectionNames(tmpSectionNames);
            setSectionValidated(Array(tmpSectionNames.length).fill(false));
        }
    }, [briefConfig]);

    const renderSection = (section: SectionConfig, index: number) => {
        const SectionComponent = SectionBuilder.getInstance().getSection(section.key);

        if (SectionComponent) {
            return (
                <Card key={index} className={`sectionCard ${index === sectionNames.length - 1 ? "lastSectionCard" : ""}`}>
                    <SectionComponent.Component
                        briefToLoad={briefFromExternalSource}
                        expanded={index === activeStep}
                        idx={index}
                        onChangeSection={onChangeSection}
                        setSectionValidated={(sectionIdx: number, validated: boolean) => { setSectionValidated(sectionValidated.map((value, idx) => idx === sectionIdx ? validated : value)) }}
                        nextButtonDisabled={!sectionValidated[index]}
                        {...section.props} />
                </Card>
            );
        }
    }

    const onBackButton = () => {
        navigate(-1);
    }

    const renderPageActionButton = () => {
        return (
            <Button onClick={onBackButton} className="briefBackButton" variant="light"><FontAwesomeIcon icon={faArrowLeft} /> {t("prev_button")}</Button>
        );
    }

    if (isEditMode && briefLoading) {
        return (
            <Page actionButton={renderPageActionButton()} category={"txt_brief"} title={"txt_input_request"} authenticated>
                <div className="fullPageLoader">
                    <PulseLoader
                        aria-label="Loading"
                    />
                </div>
            </Page>
        );
    }

    return (
        <Page actionButton={renderPageActionButton()} category={"txt_brief"} title={"txt_input_request"} authenticated>
            {selectedBrief === "" ?
                <Row>
                    <Col>
                        Veuillez passer par la selection de profil
                    </Col>
                </Row>
                :
                <Row>
                    <Col md={3}>
                        <SectionTimeLine onChangeSection={onChangeSection} activeStep={activeStep} sectionNames={sectionNames} />
                    </Col>
                    <Col md={9} >
                        {clientConfig && briefConfig &&
                            <>
                                {briefConfig.brief.sections.map(renderSection)}
                            </>
                        }
                    </Col>
                </Row>
            }
        </Page>
    )
}