import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

// Custom Link component that automatically prefixes clientId to paths
const ClientLink = ({ to, ...rest }: any) => {
    const { clientId } = useParams();
    const prefixedTo = `/${clientId}${to}`;

    return <Link {...rest} to={prefixedTo} />;
};

export default ClientLink;