import { io } from "socket.io-client";

const serverUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:3007";

export const socket = io(serverUrl, { autoConnect: false });

socket.on("connect", () => {
    console.log("Connected to socket server");
    socket.emit("authenticate", localStorage.getItem("token"));
});

socket.on("disconnect", () => {
    console.log("Disconnected from socket server");
});