import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { DefaultInput } from "../Components/Inputs/DefaultInput";
import "./Register.css";
import React from "react";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { RegisterAction, resetRegistrationData } from "../Redux/Reducers/authSlice";
import ClientLink from "../Components/Misc/ClientLink";
import { useClientIdNavigate } from "../Router";
import { useParams } from "react-router-dom";
import { Input } from "../Components/Inputs/Input";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const Register = () => {
    const { t } = useTranslation();
    const navigate = useClientIdNavigate();
    const dispatch = useAppDispatch();
    const { clientId } = useParams();
    const [error, setError] = React.useState<string>("");
    const registerErrorMsg = useAppSelector(state => state.auth.authRegistrationError);
    const registrationSuccess = useAppSelector(state => state.auth.registrationSuccess);
    const accessToken = useAppSelector(state => state.auth.accessToken);

    React.useEffect(() => {
        dispatch(resetRegistrationData());
    }, [])

    React.useEffect(() => {
        if (accessToken !== "")
            navigate('/brief');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    const errorCodeToErrorText = (errorCode: string): string => {
        switch (errorCode) {
            case "EMAIL_ALREADY_EXISTS":
                return t("txt_email_exist_err");
            case "PASSWORD_MISMATCH":
                return t("txt_pass_match_err");
            case "INVALID_EMAIL_FORMAT":
                return t("txt_please_input_valid_email");
            case "INVALID_PASSWORD_FORMAT":
                return t("txt_password_jamv2");
            case "CLIENT_NOT_FOUND":
                return "Erreur lors de la récupération du client";
            case "SUPER_CLIENT_NOT_FOUND":
                return "Erreur lors de la récupération du super client";
            case "USER_ALREADY_EXISTS_IN_SUPERCLIENT":
                return "L'utilisateur existe déjà dans le super client";
            default:
                return "";
        }
    }

    const onFormSubmit = (event: any) => {
        event.preventDefault();

        if (event.target.password.value !== event.target.passwordConfirmation.value)
            setError("PASSWORD_MISMATCH");
        else if (clientId) {
            dispatch(RegisterAction({
                firstName: event.target.firstName.value,
                lastName: event.target.lastName.value,
                email: event.target.email.value,
                password: event.target.password.value,
                clientId: clientId
            }));
        }
    }

    React.useEffect(() => {
        if (registrationSuccess) {
            toast.success(t("txt_register_success_notification"));
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registrationSuccess]);

    React.useEffect(() => {
        if (registerErrorMsg !== "")
            toast.error(t("txt_register_error_notification"));
        setError(registerErrorMsg);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerErrorMsg]);

    return (
        <Container fluid>
            <Row>
                <Col className="leftSection">
                    <Row className="mb-3">
                        <Col>
                            <img className="homeLogo" src="/img/JAM_ Logo&Slogan.svg" alt="JAM LOGO" />
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col>
                            <h1 className="registerTitle">{t('txt_register')}</h1>
                        </Col>
                    </Row>
                    <Form onSubmit={onFormSubmit}>
                        <Row className="mb-1">
                            <Col>
                                <Form.Group controlId="firstName">
                                    <Input title={t("first_name")}>
                                        <DefaultInput required type='text' iconKey='identity' placeholder={t("first_name")} />
                                    </Input>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="lastName">
                                    <Input title={t("last_name")}>
                                        <DefaultInput required type='text' iconKey='identity' placeholder={t("last_name")} />
                                    </Input>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <Form.Group controlId="email">
                                    <Input title={t("txt_email_short")}>
                                        <DefaultInput required iconKey='email' type='email' placeholder={t("txt_email_short")} />
                                    </Input>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <Form.Group controlId="password">
                                    <Input title={t("txt_pass_label")}>
                                        <DefaultInput required iconKey='password' type='password' placeholder={t("txt_pass_label")} />
                                    </Input>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <Form.Group controlId="passwordConfirmation">
                                    <Input title={t("txt_confirm_pass")}>
                                        <DefaultInput required iconKey='password' type='password' placeholder={t("txt_confirm_pass")} />
                                    </Input>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="formRequiredRow mb-1">
                            <Col>
                                *: {t("req_field")}
                            </Col>
                        </Row>
                        <Row className="errorTextRow mb-1">
                            <Col>
                                {errorCodeToErrorText(error)}
                            </Col>
                        </Row>
                        <Row className="homeButtonRow mb-4">
                            <Col>
                                <Button className="loginButton" type="submit">{t("txt_register")}</Button>
                            </Col>
                        </Row>
                        <Row className="homeButtonRow mb-1">
                            <Col className="homeLeftCol">
                                <hr />
                                <ClientLink to="/">{t("txt_register_footer")}</ClientLink>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col className="d-none d-md-block rightSection">
                    <img className="imgRightSection" src="/img/RightSection.svg" alt="Right Section" />
                </Col>
            </Row >
        </Container >
    )
};