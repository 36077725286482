import { Tabs } from "react-bootstrap"

export interface JamTabsProps {
    defaultActiveKey: string;
    children: any;
    headerClassName?: string;
}

export const JamTabs = (props: JamTabsProps) => {

    return (
        <Tabs defaultActiveKey={props.defaultActiveKey} id="justify-tab-example" className={`mb-3 ${props.headerClassName ? props.headerClassName : ""}`} justify>
            {props.children}
        </Tabs>
    )
}