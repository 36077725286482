import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import "./FormatPicker.css";
import { useAppDispatch } from "../../Redux/hooks";
import { setInputValue } from "../../Redux/Reducers/briefSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from "react-bootstrap-typeahead";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

export interface grpPerFormat {
    [key: string]: any;
    format: string;
    grpRepartitionPercent: number;
}

export interface FormatPickerProps {
    required?: boolean;
    setInputValue: (value: any, key: string) => void;
}

export const FormatPicker = (props: FormatPickerProps) => {
    const { t } = useTranslation();
    const [formats, setFormats] = React.useState<grpPerFormat[]>([]);
    const typeaheadRef = React.useRef<any>(null);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setInputValue({ key: "grpPerFormat", value: formats }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formats]);

    React.useEffect(() => {
        if (props.required && typeaheadRef.current) {
            if (formats.length === 0) {
                typeaheadRef.current.getInput().required = true;
            }
            else
                typeaheadRef.current.getInput().required = false;
        }
    }, [typeaheadRef, props.required, formats])


    return (
        <Form.Group controlId="formatPicker">
            <InputGroup className="mb-3">
                <InputGroup.Text className="inputIcon"><FontAwesomeIcon icon={faClock} /></InputGroup.Text>
                <Typeahead
                    allowNew
                    ref={typeaheadRef}
                    clearButton
                    className="formatPicker"
                    inputProps={{ type: "number" }}
                    id="formatPicker"
                    options={[]}
                    multiple
                    placeholder={t("txt_format")}
                    emptyLabel={t("txt_add_new_format")}
                    newSelectionPrefix={t("txt_new") + " : "}
                    onChange={(selected: any[]) => {
                        props.setInputValue(selected.map((s) => Number.parseInt(s.label)), "selectedFormats");
                        setFormats(selected.map((s) => ({ format: s.label, grpRepartitionPercent: 100 / selected.length})));
                    }}
                />
            </InputGroup>
        </Form.Group>
    );

}