import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ClientConfig } from "../../Business/dto/ClientConfig";
import { ApiClientManager } from "../../Business/api/ApiClientManager";
import { ClientApiClient } from "../../Business/api/ClientApiClient";

export interface ClientState {
    config?: ClientConfig;
    loadConfigError: boolean;
}

const initialState: ClientState = {
    config: undefined,
    loadConfigError: false
};

export const GetClientConfig = createAsyncThunk<ClientConfig | undefined, string | undefined>(
    'client/getConfig',
    async (clientId) => {
        if (!clientId)
            return undefined;

        const config = await ApiClientManager.getInstance().getApiClient<ClientApiClient>(ClientApiClient.key).GetClientConfig(clientId);

        return config;
    }
)

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(GetClientConfig.fulfilled, (state, action) => {
            state.config = action.payload;
            state.loadConfigError = false;
        }).addCase(GetClientConfig.rejected, (state, action) => {
            state.config = undefined;
            state.loadConfigError = true;
        });
    }
});

export default clientSlice.reducer;