import React from "react";
import { DataRetrieval } from "../../Business/dto/ExternalSystemRetrieveBody";
import { Advertiser } from "../../Business/dto/Brief";
import { ExternalDataPicker } from "./ExternalDataPicker";
import { DataKey, ExternalSystemElement } from "../../Business/dto/ExternalSystemElement";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { DefaultInput } from "./DefaultInput";

interface AdvertiserPickerProps {
    disabled?: boolean;
    retrieveAdvertisersUrl: string;
    retrieveAdvertisersBody: any;
    retrieveAdvertisersMethod: "get" | "post";
    retrieveAdvertisersParser: any;
    setSelectedAdvertiser?: (Advertiser: Advertiser) => void;
    required?: boolean;
    value?: Advertiser;
    multiple?: boolean;
    setSelectedAdvertisers?: (Advertisers: Advertiser[]) => void;
    values?: Advertiser[];
    filter?: ExternalSystemElement[];
    allowNew?: boolean;
}

export const AdvertiserPicker = (props: AdvertiserPickerProps) => {
    const { t } = useTranslation();
    const [body, setBody] = React.useState<DataRetrieval | undefined>(undefined);

    React.useEffect(() => {
        setBody({
            endpoint: props.retrieveAdvertisersUrl,
            method: props.retrieveAdvertisersMethod,
            body: props.retrieveAdvertisersBody,
            parser: props.retrieveAdvertisersParser
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.retrieveAdvertisersUrl]);

    return (
        <>
            {!props.allowNew ?
                <>
                    {body && (!props.multiple && props.setSelectedAdvertiser ?
                        <ExternalDataPicker
                            disabled={props.disabled}
                            setSelectedData={props.setSelectedAdvertiser}
                            placeholder={t("annoncer")}
                            dataKey={DataKey.Advertiser}
                            body={body}
                            icon={faBuilding}
                            filter={props.filter}
                            controlId="advertiserPicker"
                            required={props.required}
                            value={props.value ? [props.value] : []}
                        />
                        : (props.setSelectedAdvertisers && props.values) &&
                        <ExternalDataPicker
                            disabled={props.disabled}
                            setSelectedData={props.setSelectedAdvertisers}
                            placeholder={t("annoncer")}
                            multiple
                            dataKey={DataKey.Advertiser}
                            body={body}
                            icon={faBuilding}
                            filter={props.filter}
                            controlId="advertiserPicker"
                            required={props.required}
                            value={props.values ? props.values : []}
                        />
                    )
                    }
                </>
            :
                <>
                    <DefaultInput
                        required={props.required}
                        type="text"
                        iconKey="building"
                        placeholder={t("annoncer")}
                        value={props.value ? props.value.name : ""}
                        onChange={(value) => {
                            if (props.setSelectedAdvertiser)
                                props.setSelectedAdvertiser({ id: "-1", name: value, mainSectorId: "-1" });
                        }}
                    />
                </>
            }
        </>
    );
}