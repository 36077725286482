import './MediaChannelSection.css'
import React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { JamTabs } from "../Tabs/JamTabs";
import { MediaChannelDaypartTab } from "../Tabs/MediaChannelDaypartTab";
import { Section } from "./Section";
import { DataTableV2DataRow } from "../Inputs/DataTableV2";
import { BriefDTO, MediaChannel, MediaChannelDayParts } from "../../Business/dto/Brief";
import { Button, Col, Form, Row, Tab } from "react-bootstrap";
import { RetrieveDataFromExternalSource, setInputValue, setRetrievedData } from "../../Redux/Reducers/briefSlice";
import { DataKey } from "../../Business/dto/ExternalSystemElement";
import { MediaChannelBudgetTab } from "../Tabs/MediaChannelBudgetTab";
import { MediaChannelDateTab } from "../Tabs/MediaChannelDateTab";
import { MediaChannelScreenTab } from "../Tabs/MediaChannelScreenTab";
import { useTranslation } from 'react-i18next';

interface MediaChannelSectionProps {
    idx: number;
    expanded: boolean;
    onChangeSection: (sectionIdx: number) => void;
    retrieveDayPartsUrl: string;
    retrieveDayPartsMethod: string;
    retrieveDayPartsParser: any;
    setSectionValidated: (sectionIdx: number, validated: boolean) => void;
    nextButtonDisabled: boolean;
    briefToLoad?: BriefDTO;
}

export class MediaChannelSection extends Section<MediaChannelSectionProps> {
    Name = "txt_mediachannel_criteria";

    SectionBody = (props: MediaChannelSectionProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const mediaChannels = useAppSelector(state => state.brief.inputData.selectedMediaChannels);
        const dayParts = useAppSelector(state => state.brief.retrievedData[DataKey.DayPart]);
        const budgetFromGeneral = useAppSelector(state => state.brief.inputData.netBudget);
        const [isSectionValidated, setIsSectionValidated] = React.useState<boolean>(true);
        const [budgetTabErrors, setBudgetTabErrors] = React.useState<string[]>([]);
        const [isBudgetTabErrorsDisplayed, setIsBudgetTabErrorsDisplayed] = React.useState<boolean>(false);
        const [dayPartTabErrors, setDayPartTabErrors] = React.useState<string[]>([]);
        const [isDayPartTabErrorsDisplayed, setIsDayPartTabErrorsDisplayed] = React.useState<boolean>(false);
        const [data, setData] = React.useState<DataTableV2DataRow[]>([]);

        React.useEffect(() => {
            if (mediaChannels) {
                const tmpMediaChannels = mediaChannels as MediaChannel[];

                if (tmpMediaChannels.length > 0) {
                    let endpoint = props.retrieveDayPartsUrl;

                    for (let i = 0; i < tmpMediaChannels.length; i++) {
                        endpoint += `${tmpMediaChannels[i].id}`;

                        if (i < tmpMediaChannels.length - 1) {
                            endpoint += ",";
                        }
                    }

                    dispatch(RetrieveDataFromExternalSource({
                        dataKey: DataKey.DayPart,
                        body: { endpoint: endpoint, method: props.retrieveDayPartsMethod as "get" | "post", parser: props.retrieveDayPartsParser }
                    }))
                }
                else
                    dispatch(setRetrievedData({ key: DataKey.DayPart, value: [] }));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch, mediaChannels, props.retrieveDayPartsMethod, props.retrieveDayPartsParser, props.retrieveDayPartsUrl])

        React.useEffect(() => {
            if (mediaChannels) {
                const tmpData: DataTableV2DataRow[] = [];
                const tmpMediaChannels = mediaChannels as MediaChannel[];

                tmpMediaChannels.forEach((mediaChannel) => {
                    const mediaChannelData: DataTableV2DataRow = { label: { value: mediaChannel.name, editable: false, isRowHeader: true } }

                    // budget tab
                    mediaChannelData.budget = { value: "", editable: true, type: "number" }

                    // daypart tab
                    if (dayParts) {
                        const mediaChannelDayParts = dayParts as MediaChannelDayParts[];

                        mediaChannelDayParts.find((dayPart) => dayPart.name === mediaChannel.name)?.dayParts.forEach((dayPart) => {
                            mediaChannelData[dayPart.name] = { value: "", editable: true, type: "percentage", additionalProperties: { isDayPart: true, timeRange: dayPart.timeRange } };
                        });
                    }

                    // date tab
                    mediaChannelData.noAllocationBefore = { value: "", editable: true, type: "time" }
                    mediaChannelData.noAllocationAfter = { value: "", editable: true, type: "time" }
                    mediaChannelData.campaignStartTime = { value: "", editable: true, type: "time" }
                    mediaChannelData.campaignEndTime = { value: "", editable: true, type: "time" }
                    mediaChannelData.minAllocationPerDay = { value: "", editable: true, type: "number" }
                    mediaChannelData.maxAllocationPerDay = { value: "", editable: true, type: "number" }
                    mediaChannelData.allocationOffsetMinutes = { value: "", editable: true, type: "number" }

                    mediaChannelData.screenMethodType = {
                        value: "gross", editable: true, type: "picker", pickerOptions: [
                            { value: "gross", label: t("txt_gross") },
                            { value: "broadcast_gross", label: t("txt_broadcast_gross") },
                            { value: "pressure", label: t("txt_pressure") },
                        ]
                    }
                    mediaChannelData.DI2Value = { value: "", editable: true, type: "percentage", additionalProperties: { isEP: true } };
                    mediaChannelData.DI4Value = { value: "", editable: true, type: "percentage", additionalProperties: { isEP: true } };
                    mediaChannelData.DI6Value = { value: "", editable: true, type: "percentage", additionalProperties: { isEP: true } };

                    tmpData.push(mediaChannelData);
                });

                setData(tmpData);
            }
        }, [mediaChannels, dayParts, t])

        const onCellEdit = (rowIdx: number, key: string, value: string) => {
            const newData = data.slice();

            if (key === "screenMethodType") {
                // Propagate the selection to all the other screenMethodType cells
                newData.forEach((_row, idx) => {
                    newData[idx] = { ...newData[idx], [key]: { ...newData[idx][key], value: value } };
                });
            }

            newData[rowIdx] = { ...newData[rowIdx], [key]: { ...newData[rowIdx][key], value: value } };

            setData(newData);
        }

        const onCellEditErrorBudget = (rowIdx: number, key: string, value: string) => {
            setIsSectionValidated(false);
            setIsBudgetTabErrorsDisplayed(true);
            onCellEdit(rowIdx, key, value);
        }

        const onCellEditErrorDayPart = (rowIdx: number, key: string, value: string) => {
            setIsSectionValidated(false);
            setIsDayPartTabErrorsDisplayed(true);
            onCellEdit(rowIdx, key, value);
        }

        React.useEffect(() => {
            const verificationResult = verifyAll();
            setIsSectionValidated(verificationResult);
            props.setSectionValidated(props.idx, verificationResult);
            const payload = { key: "mediaChannelsTab", value: data };
            dispatch(setInputValue(payload));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data])

        // Verify that the section is correctly filled
        const verifyAll = (): boolean => {
            const isVerifiedBudegTab = isBudgetTabErrorsDisplayed ? verifyBudgetTab() : true;
            const isVerifiedDaypartTab = isDayPartTabErrorsDisplayed ? verifyDaypartTab() : true;
            return isVerifiedBudegTab && isVerifiedDaypartTab;
        }

        const verifyBudgetTab = (): boolean => {
            let isValid = true;
            let totalBudget = 0;
            data.forEach((row) => {
                if (row.budget.value === "") {
                    isValid = false;
                }
                else {
                    totalBudget += parseInt(row.budget.value);
                }
            });
            isValid = isValid && totalBudget === budgetFromGeneral;
            isValid || !isBudgetTabErrorsDisplayed ? setBudgetTabErrors([]) : setBudgetTabErrors([t("txt_budget_error", { budgetDifference: (budgetFromGeneral - totalBudget) })]);
            return isValid;
        }

        const verifyDaypartTab = (): boolean => {
            let isValid;
            let errors: string[] = [];
            data.forEach((row) => {
                let totalRepartion = 0;
                Object.keys(row).forEach((key) => {
                    if (row[key].additionalProperties?.isDayPart) {
                        totalRepartion += parseInt(row[key].value);
                    }
                });
                if (totalRepartion !== 100) {
                    errors.push(t("txt_daypart_error", { mediaChannel: row.label.value }));
                }
            });
            isValid = errors.length === 0;
            !isDayPartTabErrorsDisplayed ? setDayPartTabErrors([]) : setDayPartTabErrors(errors);
            return isValid;
        }


        const onFormSubmit = (event: any) => {
            event.preventDefault();

            props.onChangeSection(props.idx + 1);
        }

        const renderErrors = (errors: string[]) => {
            return errors.map((error) => {
                return (
                    <Row>
                        <Col style={{ color: "red" }}>{error}</Col>
                    </Row>
                );
            });
        }

        return (
            <Form onSubmit={onFormSubmit}>
                <JamTabs headerClassName='mediaChannelTabHeader' defaultActiveKey="budget">
                    <Tab eventKey="budget" title={t("txt_budget_grpc")} tabClassName="jamtab">
                        <MediaChannelBudgetTab data={data} onCellEdit={onCellEditErrorBudget} />
                        {renderErrors(budgetTabErrors)}
                    </Tab>
                    <Tab eventKey="daypart" title={t("txt_day_part")} tabClassName="jamtab" >
                        <MediaChannelDaypartTab data={data} onCellEdit={onCellEditErrorDayPart} dayPartsForMediaChannel={dayParts as MediaChannelDayParts[]} />
                        {renderErrors(dayPartTabErrors)}
                    </Tab>
                    <Tab eventKey="date" title={`${t("txt_date")}/${t("txt_hour")}`} tabClassName="jamtab" >
                        <MediaChannelDateTab data={data} onCellEdit={onCellEdit} />
                    </Tab>
                    <Tab eventKey="screens" title={t("txt_screen")} tabClassName="jamtab" >
                        <MediaChannelScreenTab data={data} onCellEdit={onCellEdit} />
                    </Tab>
                </JamTabs>
                <Col className="text-center">
                    <Button type="submit" disabled={!isSectionValidated} className="sectionContinueButton" variant="dark">{t("next_button")}</Button>
                </Col>
            </Form>
        );
    }
}