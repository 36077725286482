import React from "react";
import { DataRetrieval } from "../../Business/dto/ExternalSystemRetrieveBody";
import { Agency } from "../../Business/dto/Brief";
import { ExternalDataPicker } from "./ExternalDataPicker";
import { DataKey } from "../../Business/dto/ExternalSystemElement";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { DefaultInput } from "./DefaultInput";

interface AgencyPickerProps {
    retrieveAgenciesUrl: string;
    retrieveAgenciesBody: any;
    retrieveAgenciesMethod: "get" | "post";
    retrieveAgenciesParser: any;
    setSelectedAgency?: (Agency: Agency) => void;
    required?: boolean;
    value?: Agency;
    multiple?: boolean;
    setSelectedAgencies?: (Agencies: Agency[]) => void;
    values?: Agency[];
    filter?: Agency[];
    disabled?: boolean;
    allowNew?: boolean;
}

export const AgencyPicker = (props: AgencyPickerProps) => {
    const { t } = useTranslation();
    const [body, setBody] = React.useState<DataRetrieval | undefined>(undefined);

    React.useEffect(() => {
        setBody({
            endpoint: props.retrieveAgenciesUrl,
            method: props.retrieveAgenciesMethod,
            body: props.retrieveAgenciesBody,
            parser: props.retrieveAgenciesParser
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!props.allowNew ?
                <>
                    {body && (!props.multiple && props.setSelectedAgency ?
                        <ExternalDataPicker
                            required={props.required}
                            setSelectedData={props.setSelectedAgency}
                            placeholder={t("txt_agency")}
                            dataKey={DataKey.Agency}
                            body={body}
                            icon={faBuilding}
                            disabled={props.disabled}
                            filter={props.filter}
                            controlId="agencyPicker"
                            value={props.value ? [props.value] : []}
                        />
                        : (props.setSelectedAgencies && props.values) &&
                        <ExternalDataPicker
                            required={props.required}
                            setSelectedData={props.setSelectedAgencies}
                            placeholder={t("txt_agency")}
                            multiple
                            dataKey={DataKey.Agency}
                            body={body}
                            icon={faBuilding}
                            disabled={props.disabled}
                            filter={props.filter}
                            controlId="agencyPicker"
                            value={props.values ? props.values : []}
                        />
                    )}
                </>
            :
                <>
                    <DefaultInput
                        required={props.required}
                        type="text"
                        iconKey="building"
                        placeholder={t("txt_agency")}
                        value={props.value ? props.value.name : ""}
                        onChange={(value) => {
                            if (props.setSelectedAgency)
                                props.setSelectedAgency({ id: "-1", name: value });
                        }}
                    />
                </>
            }
        </>
    );
}