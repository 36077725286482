import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

export interface LocaleState {
    locale: string;
}

export const initialState: LocaleState = {
    locale: localStorage.getItem("currentLocal") || "fr"
}

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLocale: (state, action: PayloadAction<string>) => {
            state.locale = action.payload;
            localStorage.setItem("currentLocal", action.payload);
            i18next.changeLanguage(action.payload)
            window.location.reload();
        }
    }
});

export const { setLocale } = localeSlice.actions;

export default localeSlice.reducer;