import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { DataKey, ExternalSystemElement } from "../../Business/dto/ExternalSystemElement";
import { RetrieveDataFromExternalSource } from "../../Redux/Reducers/briefSlice";
import { DataRetrieval } from "../../Business/dto/ExternalSystemRetrieveBody";
import { ChoicePicker } from "./ChoicePicker";

interface ExternalDataPickerProps {
    setSelectedData: (data: any) => void;
    placeholder: string;
    dataKey: DataKey;
    displayCode?: boolean;
    body: DataRetrieval;
    multiple?: boolean;
    disabled?: boolean;
    icon?: IconDefinition;
    required?: boolean;
    controlId: string;
    value: any[];
    filter?: ExternalSystemElement[];
    allowNew?: boolean;
}

export const ExternalDataPicker = (props: ExternalDataPickerProps) => {
    const dispatch = useAppDispatch();
    const data: ExternalSystemElement[] = useAppSelector(state => state.brief.retrievedData[props.dataKey]);
    const [finalData, setFinalData] = React.useState<ExternalSystemElement[]>([]);
    const [lastBody, setLastBody] = React.useState<DataRetrieval | undefined>(undefined);

    React.useEffect(() => {
        if (!props.disabled && (lastBody === undefined || JSON.stringify(lastBody) !== JSON.stringify(props.body))) {
            dispatch(RetrieveDataFromExternalSource({ dataKey: props.dataKey, body: props.body }));
            setLastBody(props.body);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.body]);

    React.useEffect(() => {
        if (props.filter && props.filter.length > 0) {
            const filteredData = data.filter(d => props.filter?.find(f => f.id === d.id));
            setFinalData(filteredData);

            if (data.length === 0)
                setFinalData(props.filter);
        }
        else
            setFinalData(data);
    }, [data, props.filter]);

    return (
        <ChoicePicker
            setSelectedData={props.setSelectedData}
            placeholder={props.placeholder}
            displayCode={props.displayCode}
            data={finalData}
            multiple={props.multiple}
            disabled={props.disabled}
            icon={props.icon}
            required={props.required}
            controlId={props.controlId}
            value={props.value}
            allowNew={props.allowNew}
        />
);
}