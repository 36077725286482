import { useTranslation } from "react-i18next";
import { DataTableV2, DataTableV2DataHeader, DataTableV2DataRow } from "../Inputs/DataTableV2";


export interface MediaChannelScreenTabProps {
    data: DataTableV2DataRow[];
    onCellEdit: (rowIdx: number, key: string, value: string) => void;
}

export const MediaChannelScreenTab = (props: MediaChannelScreenTabProps) => {
    const { t } = useTranslation();
    const headers: DataTableV2DataHeader[] = [
        { name: "", dataKey: "label", showHeader: false },
        { name: t("txt_selling_strategy"), dataKey: "screenMethodType", showHeader: true },
        { name: "DI2 (%)", dataKey: "DI2Value", showHeader: true },
        { name: "DI4 (%)", dataKey: "DI4Value", showHeader: true },
        { name: "DI6 (%)", dataKey: "DI6Value", showHeader: true },
    ]

    return (
        <>
            <DataTableV2
                headers={headers}
                data={props.data}
                editValue={props.onCellEdit}
            />
        </>
    )
}