import { ExternalSystemElement } from "./ExternalSystemElement";

export enum UserRoles {
    USER = "USER",
    ADMIN = "ADMIN",
    SUPERADMIN = "SUPERADMIN",
}

export interface UserDetails {
    firstName: string;
    lastName: string;
    email: string;
    briefsByClient: [
        {
            clientId: string;
            briefs: string[]
        }
    ];
    active?: boolean,
    qualified?: boolean,
    external?: boolean,
    accountAdminEmail?: string,
    agencies: ExternalSystemElement[],
    advertisers: ExternalSystemElement[],
    roles: UserRoles[],
    _id: string;
    maxAllocationPerDay: number;
}