import { Currency } from "../dto/Brief";

export const getCurrencySymbol = (currency: Currency | undefined | string): string => {
    if (currency === undefined || typeof currency === "string")
        return "";

    return currency.code;
}

export const formatNumberToCurrency = (value: number, currency: Currency | undefined | string): string => {
    // Add spaces to the number
    const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return formattedValue + " " + getCurrencySymbol(currency);
}