import React from "react";
import { DataTableV2, DataTableV2DataHeader, DataTableV2DataRow } from "../Inputs/DataTableV2";
import { DayPart, MediaChannelDayParts } from "../../Business/dto/Brief";
import { useTranslation } from "react-i18next";


export interface MediaChannelDaypartTabProps {
    data: DataTableV2DataRow[];
    onCellEdit: (rowIdx: number, key: string, value: string) => void;
    dayPartsForMediaChannel: MediaChannelDayParts[];
}

export const MediaChannelDaypartTab = (props: MediaChannelDaypartTabProps) => {
    const { t } = useTranslation();
    const [headers, setHeaders] = React.useState<DataTableV2DataHeader[]>([]);

    React.useEffect(() => {
        if (props.dayPartsForMediaChannel.length > 0) {
            let globalDayParts: DayPart[] = [];
            const tmpHeaders: DataTableV2DataHeader[] = [];

            tmpHeaders.push({ name: "", dataKey: "label", showHeader: false });

            props.dayPartsForMediaChannel.forEach((mediaChannelDayParts) => {
                mediaChannelDayParts.dayParts.forEach((dayPart) => {
                    if (!globalDayParts.find(d => d.name === dayPart.name))
                        globalDayParts.push(dayPart);
                });
            });

            globalDayParts = globalDayParts.sort((a, b) => {
                const startTimeA = parseInt(a.timeRange.split('-')[0].split(':').join(''));
                const startTimeB = parseInt(b.timeRange.split('-')[0].split(':').join(''));
                
                if (startTimeA < startTimeB)
                    return -1;
                if (startTimeA > startTimeB)
                    return 1;

                return 0;
            });

            globalDayParts.forEach((dayPart) => {
                tmpHeaders.push({ name: `${t("txt_percent_of")} ` + dayPart.name, dataKey: dayPart.name, showHeader: true });
            });

            setHeaders(tmpHeaders);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dayPartsForMediaChannel]);

    return (
        <>
            <DataTableV2
                headers={headers}
                data={props.data}
                editValue={props.onCellEdit}
            />
        </>
    )
}