import React from "react";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { GetUserList } from "../Redux/Reducers/userSlice";
import { Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { t } from "i18next";
import './UserList.css';
import { UserDetails } from "../Business/dto/UserDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useClientIdNavigate } from "../Router";

interface TableColProps {
    key: keyof UserDetails;
    label: string;
}

export const UserList = () => {
    const dispatch = useAppDispatch();
    const navigate = useClientIdNavigate();
    const users = useAppSelector(state => state.user.userList);
    const [tabKey, setTabKey] = React.useState<string>("All");
    const [seeOnlyNew, setSeeOnlyNew] = React.useState<boolean>(false);
    const [filter, setFilter] = React.useState<string>('');
    const [cols] = React.useState<TableColProps[]>([
        { key: "lastName", label: t("last_name") },
        { key: "firstName", label: t("first_name") },
        { key: "email", label: "E-mail" },
        { key: "roles", label: "Roles" },
        { key: "active", label: t("active") },
        { key: "qualified", label: t("txt_confirmed") },
        { key: "external", label: t("external") },
        { key: "accountAdminEmail", label: t("supervisor") }
    ]);
    const defaultSort = {
        columnName: '',
        ascending: false,
    }
    const [sortOrder, setSortOrder] = React.useState(defaultSort);
    const [filteredUsers, setFilteredUsers] = React.useState<UserDetails[]>([]);

    React.useEffect(() => {
        dispatch(GetUserList());
    }, [dispatch]);

    React.useEffect(() => {
        setFilteredUsers(users);
    }, [users]);

    React.useEffect(() => {
        if (users.length === 0)
            return

        let newFilteredUsers = users.filter((user: UserDetails) => {
            let satisfiesSearch = false
            let satisfiesSeeOnlyNew = true

            // prénom, nom, société, email
            if (`${user.firstName} ${user.lastName} ${user.firstName} ${user.roles} ${user.email} ${user.accountAdminEmail}`.toLowerCase().includes(filter) || filter === '') {
                satisfiesSearch = true
            }
            if (seeOnlyNew && user.qualified) satisfiesSeeOnlyNew = false

            return satisfiesSearch && satisfiesSeeOnlyNew
        })

        setFilteredUsers(newFilteredUsers)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, seeOnlyNew])

    const handleTabSelect = (key: string | null) => {
        if (key === null) return

        setSeeOnlyNew(key === "New")
        setTabKey(key)
    }

    const handleSearch = (e: any) => {
        setFilter(e.target.value.toString().toLowerCase())
    }

    const handleSortColumn = (columnName: string) => {
        const isAscending = sortOrder.columnName === columnName ? !sortOrder.ascending : true

        const sorted = [...filteredUsers].sort((a: any, b: any) => {
            const compareResult = a[columnName]?.toString().toLowerCase().localeCompare(b[columnName]?.toString().toLowerCase());
            return isAscending ? compareResult : -compareResult
        })

        setFilteredUsers(sorted)
        setSortOrder({
            columnName,
            ascending: isAscending,
        })
    }

    const handleClickOnUser = (id: string) => {
        navigate(`/userDetails/${id}`, { state: { userDetails: users.find((user) => user._id === id) } })
    }

    return (
        <Row>
            <Col>
                <Row className="userListTabsRow" style={{ justifyContent: "space-between" }}>
                    <Col style={{ flexGrow: 1 }}>
                        <Tabs id="tabs" activeKey={tabKey} onSelect={handleTabSelect}>
                            <Tab eventKey="All" title={t('txt_user_all')} />
                            <Tab eventKey="New" title={t('txt_user_to_be_confirmed')} />
                        </Tabs>
                    </Col>
                    <Col style={{ flexGrow: 0 }}>
                        <Form.Control style={{ width: "350px", margin: "0.5em" }} type="text" placeholder={t('txt_search')} onChange={handleSearch} />
                    </Col>
                </Row>
                <Row>
                    <Table bordered hover responsive>
                        <thead>
                            <tr>
                                {cols.map((entry, idx) => (
                                    <th key={idx} className="text-center position-relative" onClick={() => handleSortColumn(entry.key)}>
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex align-items-center">
                                                <SortIcon column={entry.key} sort={sortOrder} />
                                                <div>{entry.label}</div>
                                            </div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map((user, idx) => (
                                <UserRow key={idx} user={user} onClick={handleClickOnUser}></UserRow>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Col>
        </Row>
    );
}

const UserRow = (props: any) => {
    const clientConfig = useAppSelector(state => state.clientConfig.config);
    const user: UserDetails = props.user

    const handleOnClick = () => {
        props.onClick(user._id)
    }

    return (
        <tr onClick={handleOnClick}>
            <td className="whitespace-nowrap">{user.lastName}</td>
            <td className="whitespace-nowrap">{user.firstName}</td>
            <td className="whitespace-nowrap">{user.email}</td>
            <td>{user.roles.join(", ")}</td>
            <td className="td-align-center">{user.active && <FontAwesomeIcon icon={faCheckCircle} style={{ color: clientConfig?.primaryColor }} />}</td>
            <td className="td-align-center">{user.qualified && <FontAwesomeIcon icon={faCheckCircle} style={{ color: clientConfig?.primaryColor }} />}</td>
            <td className="td-align-center">{user.external && <FontAwesomeIcon icon={faCheckCircle} style={{ color: clientConfig?.primaryColor }} />}</td>
            <td>{user.accountAdminEmail}</td>
        </tr>
    )
}

const SortIcon = ({ column, sort }: any) => {
    const clientConfig = useAppSelector(state => state.clientConfig.config);
    if (column !== sort.columnName) return <></>
    const icon = (sort.ascending === true) ? faArrowUp : faArrowDown
    return <FontAwesomeIcon icon={icon} style={{ width: '15px', height: '15px', color: clientConfig?.primaryColor, position: 'absolute', top: 5, right: 5 }} />
}