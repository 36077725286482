import { Form, InputGroup } from 'react-bootstrap';
import './DefaultInput.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faIdCard, faKeyboard, faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import { faAt, faBuilding, faLock, faPerson } from '@fortawesome/free-solid-svg-icons';

export interface DefaultInputProps {
    onChange?: (value: any) => void;
    type: "number" | "email" | "password" | "text";
    iconKey?: string;
    placeholder: string;
    required?: boolean;
    value?: any;
}

export const DefaultInput = (props: DefaultInputProps) => {
    const [inputValue, setInputValue] = React.useState<any>("");

    React.useEffect(() => {
        if (props.value !== undefined)
            setInputValue(props.value);
    }, [props.value])

    const renderIcon = (): IconDefinition => {
        switch (props.iconKey) {
            case "currency":
                return faMoneyBill1;
            case "email":
                return faAt;
            case "password":
                return faLock;
            case "name":
                return faPerson;
            case "identity":
                return faIdCard;
            case "building":
                return faBuilding;
            default:
                return faKeyboard;
        }
    }

    const onInputChange = (event: any) => {
        if (props.type === "number") {
            let value = parseFloat(event.target.value);

            if (value > 0) {
                if (props.onChange !== undefined)
                    props.onChange(value);
                setInputValue(value);
            }
            else {
                setInputValue("");
                if (props.onChange !== undefined)
                    props.onChange(0);
            }
        }
        else {
            if (props.onChange !== undefined)
                props.onChange(event.target.value);
            setInputValue(event.target.value);
        }
    }

    return (
        <>
            {
                props.iconKey ?
                    <InputGroup>
                        <InputGroup.Text className="inputIcon"><FontAwesomeIcon width={16} icon={renderIcon()} /></InputGroup.Text>
                        <Form.Control
                            required={props.required}
                            className="defaultInput"
                            onChange={onInputChange}
                            value={inputValue}
                            type={props.type}
                            placeholder={props.placeholder}
                        />
                    </InputGroup>
                    :
                    <Form.Control
                        required={props.required}
                        className="defaultInput"
                        onChange={onInputChange}
                        value={inputValue}
                        type={props.type}
                        placeholder={props.placeholder} />
            }
        </>
    );
}