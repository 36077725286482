import React from "react";
import { Target } from "../../Business/dto/Brief";
import { DataRetrieval } from "../../Business/dto/ExternalSystemRetrieveBody";
import { ExternalDataPicker } from "./ExternalDataPicker";
import { DataKey } from "../../Business/dto/ExternalSystemElement";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export interface TargetPickerProps {   
    retrieveTargetsUrl: string;
    retrieveTargetsBody: any;
    retrieveTargetsMethod: "get" | "post";
    retrieveTargetsParser: any;
    setSelectedTarget: (sector: Target | undefined) => void;
    required?: boolean;
    value: Target | undefined;
}

export const TargetPicker = (props: TargetPickerProps) => {
    const { t } = useTranslation();
    const [body, setBody] = React.useState<DataRetrieval | undefined>(undefined);

    React.useEffect(() => {
        setBody({
            endpoint: props.retrieveTargetsUrl,
            method: props.retrieveTargetsMethod,
            body: props.retrieveTargetsBody,
            parser: props.retrieveTargetsParser
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
            {body &&
                <ExternalDataPicker
                    setSelectedData={props.setSelectedTarget}
                    placeholder={t("txt_target")}
                    displayCode={false}
                    dataKey={DataKey.Target}
                    body={body}
                    icon={faUser}
                    controlId="targetPicker"
                    required={props.required}
                    value={props.value ? [props.value] : []}
                />
            }
        </>
    )
};