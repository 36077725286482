export class StringReplacer {
    public static ReplaceInString(sourceString: string, startDelimiter: string, endDelimiter: string, isDestArray: boolean, ...args: any[]): string {
        let result = sourceString;

        for (let i = 0; i < args.length; i++) {
            result = result.replace(startDelimiter + i + endDelimiter, args[i]);
        }

        return result;
    }
}

export class JsonReplacer {
    private static ReplaceInJsonRecursive(json: any, keys: string[], value: any): any {
        if (keys.length === 1) {
            json[keys[0]] = value;
            return json;
        }
        else {
            json[keys[0]] = JsonReplacer.ReplaceInJsonRecursive(json[keys[0]], keys.slice(1), value);
            return json;
        }
    }

    public static ReplaceInJson(json: any, keysToReplace: string[], ...args: any[]): any {
        const duplicatedJson = JSON.parse(JSON.stringify(json));

        keysToReplace.forEach((keyToReplace, idx) => {
            const keys = keyToReplace.split(".");
            json = JsonReplacer.ReplaceInJsonRecursive(duplicatedJson, keys, args[idx]);
        });

        return duplicatedJson;
    }
}