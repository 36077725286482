import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './Input.css';

export interface InputProps {
    title: string;
    children: any;
    required?: boolean;
}

export class Input extends React.Component<InputProps> {
    renderChildren() {
        return React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, { required: this.props.required });
        });
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col><b className="inputTitleText">{this.props.title}{this.props.required ? <span className="requiredStar"> *</span> : ""}</b></Col>
                </Row>
                <Row>
                    <Col>
                        {this.renderChildren()}
                    </Col>
                </Row>
            </Container>
        );
    }
};