/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { PeriodPicker } from '../Inputs/PeriodPicker';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Section } from './Section';
import { DaysPicker } from '../Inputs/DaysPicker';
import { Input } from '../Inputs/Input';
import { SectorPicker } from '../Inputs/SectorPicker';
import { Advertiser, Agency, BriefDTO, Country, Currency, MediaChannel, Product, Sector, Target } from '../../Business/dto/Brief';
import { TargetPicker } from '../Inputs/TargetPicker';
import './GeneralSection.css';
import { DefaultInput } from '../Inputs/DefaultInput';
import { MediaChannelPicker } from '../Inputs/MediaChannelPicker';
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { RetrieveDataFromExternalSource, setInputValue } from '../../Redux/Reducers/briefSlice';
import { FormatPicker } from '../Inputs/FormatPicker';
import { CountryPicker } from '../Inputs/CountryPicker';
import { JsonReplacer, StringReplacer } from '../../Business/utils/StringReplacer';
import { CurrencyPicker } from '../Inputs/CurrencyPicker';
import { AgencyPicker } from '../Inputs/AgencyPicker';
import { AdvertiserPicker } from '../Inputs/AdvertiserPicker';
import { ProductPicker } from '../Inputs/ProductPicker';
import { DataKey } from '../../Business/dto/ExternalSystemElement';
import { DataRetrieval } from '../../Business/dto/ExternalSystemRetrieveBody';
import { useTranslation } from 'react-i18next';
import { SellingStrategyOption, SellingStrategyPicker } from '../Inputs/SellingStrategyPicker';

interface GeneralSectionProps {
    setSectionValidated: (sectionIdx: number, validated: boolean) => void;
    nextButtonDisabled: boolean;
    idx: number;
    maxMediaSelectionCount: number;
    retrieveSectorsUrl: string;
    retrieveSectorsBody: any;
    retrieveSectorsMethod: "get" | "post";
    retrieveSectorsParser: any;
    retrieveTargetsUrl: string;
    retrieveTargetsBody: any;
    retrieveTargetsMethod: "get" | "post";
    retrieveTargetsParser: any;
    retrieveMediaChannelsUrl: string;
    retrieveMediaChannelsBody: any;
    retrieveMediaChannelsMethod: "get" | "post";
    retrieveMediaChannelsParser: any;
    expanded: boolean;
    onChangeSection: (sectionIdx: number) => void;
    retrieveCountriesUrl: string;
    retrieveCountriesBody: any;
    retrieveCountriesMethod: "get" | "post";
    retrieveCountriesParser: any;
    useCountryIdToRetrieveMediaChannels: boolean;
    retrieveCurrenciesUrl: string;
    retrieveCurrenciesBody: any;
    retrieveCurrenciesMethod: "get" | "post";
    retrieveCurrenciesParser: any;
    retrieveAgenciesUrl: string;
    retrieveAgenciesBody: any;
    retrieveAgenciesMethod: "get" | "post";
    retrieveAgenciesParser: any;
    retrieveAdvertisersUrl: string;
    retrieveAdvertisersBody: any;
    retrieveAdvertisersMethod: "get" | "post";
    retrieveAdvertisersParser: any;
    retrieveProductsUrl: string;
    retrieveProductsBody: any;
    retrieveProductsMethod: "get" | "post";
    retrieveProductsParser: any;
    retrieveCompetitionCodesUrl: string;
    retrieveCompetitionCodesBody: any;
    retrieveCompetitionCodesKeysToReplaceInBody: string[];
    retrieveCompetitionCodesMethod: "get" | "post";
    retrieveCompetitionCodesParser: any;
    requireSellingStrategy: boolean;
    briefToLoad?: BriefDTO;
}

interface GeneralSectionInputedValues {
    startDate: number;
    endDate: number;
    selectedDays: number[];
    selectedAgency?: Agency;
    selectedAdvertiser?: Advertiser;
    selectedProduct?: Product;
    selectedSector?: Sector;
    selectedTarget?: Target;
    selectedMediaChannels: MediaChannel[];
    selectedCountry?: Country;
    selectedCurrency?: Currency;
    netBudget: number;
    sellingStrategyType?: SellingStrategyOption;
    sellingStrategyValue?: number;
    selectedFormats: number[];
}

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export class GeneralSection extends Section<GeneralSectionProps> {
    Name = "txt_general_criteria";

    SectionBody = (props: GeneralSectionProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const userDetails = useAppSelector(state => state.user.details);
        const retrievedSectors = useAppSelector(state => state.brief.retrievedData[DataKey.Sector]);
        const retrievedMediaChannels = useAppSelector(state => state.brief.retrievedData[DataKey.MediaChannel]);
        const competitionCodes = useAppSelector(state => state.brief.retrievedData[DataKey.Competition]);
        const [inputValues, setInputValues] = React.useState<GeneralSectionInputedValues>({
            startDate: tomorrow.getFullYear() * 10000 + (tomorrow.getMonth() + 1) * 100 + tomorrow.getDate(),
            endDate: tomorrow.getFullYear() * 10000 + (tomorrow.getMonth() + 1) * 100 + tomorrow.getDate(),
            selectedDays: [],
            selectedMediaChannels: [],
            selectedFormats: [],
            netBudget: 0,
            selectedAdvertiser: undefined,
            selectedAgency: undefined,
            selectedCountry: undefined,
            selectedCurrency: undefined,
            sellingStrategyType: undefined,
            sellingStrategyValue: undefined
        });
        const [isDirectAdvertiser, setIsDirectAdvertiser] = React.useState<boolean>(false);

        const setValues = (value: any, key: string) => {
            setInputValues({ ...inputValues, [key]: value });
            dispatch(setInputValue({ key, value }));
        }

        React.useEffect(() => {
            if (props.briefToLoad) {
                console.log("generalsection brief to load", props.briefToLoad)
            }
        }, [props.briefToLoad])

        React.useEffect(() => {
            // Validate data
            if (userDetails) {
                const valid = (inputValues.netBudget > 0 &&
                    inputValues.selectedCurrency !== undefined &&
                    inputValues.selectedCountry !== undefined &&
                    inputValues.selectedMediaChannels.length > 0 &&
                    inputValues.selectedAdvertiser !== undefined &&
                    (inputValues.selectedAdvertiser !== undefined || (userDetails.agencies.length === 0 && userDetails.advertisers.length > 0)) &&
                    inputValues.selectedProduct !== undefined &&
                    inputValues.selectedSector !== undefined &&
                    inputValues.selectedTarget !== undefined &&
                    inputValues.selectedFormats.length > 0 &&
                    isSellingStrategyValid()
                )

                props.setSectionValidated(props.idx, valid);
            }
        }, [inputValues, userDetails])

        React.useEffect(() => {
            if (userDetails?.agencies.length === 0 && userDetails?.advertisers.length > 0) {
                setIsDirectAdvertiser(true);
            }
        }, [userDetails])

        React.useEffect(() => {
            const selectedAdvertiser = inputValues.selectedAdvertiser;
            if (retrievedSectors.length > 0 && selectedAdvertiser && selectedAdvertiser.mainSectorId !== "" && selectedAdvertiser.mainSectorId !== "-1") {
                setValues(retrievedSectors.find(sector => sector.id === selectedAdvertiser.mainSectorId), "selectedSector");
            }

            if (!selectedAdvertiser)
                setValues(undefined, "selectedProduct");
        }, [inputValues.selectedAdvertiser, retrievedSectors])

        React.useEffect(() => {
            if (inputValues.selectedSector && retrievedMediaChannels && retrievedMediaChannels.length > 0) {
                const retrievedMediaChannelIds = retrievedMediaChannels.map(mediaChannel => mediaChannel.id);
                const body = JsonReplacer.ReplaceInJson(props.retrieveCompetitionCodesBody, props.retrieveCompetitionCodesKeysToReplaceInBody, retrievedMediaChannelIds, [inputValues.selectedSector.id]);
                const dataRetrievalBody: DataRetrieval = {
                    endpoint: props.retrieveCompetitionCodesUrl,
                    method: props.retrieveCompetitionCodesMethod,
                    body: body,
                    parser: props.retrieveCompetitionCodesParser
                }

                dispatch(RetrieveDataFromExternalSource({ dataKey: DataKey.Competition, body: dataRetrievalBody }));
            }
        }, [retrievedMediaChannels, inputValues.selectedSector]);

        React.useEffect(() => {
            if (!inputValues.selectedAgency)
                setValues(undefined, "selectedAdvertiser");
        }, [inputValues.selectedAgency])

        React.useEffect(() => {
            dispatch(setInputValue({ key: "competitionCodes", value: competitionCodes }));
        }, [competitionCodes]);

        const onFormSubmit = (event: any) => {
            event.preventDefault();
            setValues(inputValues.startDate, "startDate");
            setValues(inputValues.endDate, "endDate");
            props.onChangeSection(props.idx + 1);
        }

        const onDirectAdvertiserClick = () => {
            setIsDirectAdvertiser(!isDirectAdvertiser);
        }

        const isSellingStrategyValid = (): boolean => {
            // if fields are not displayed, return OK
            if (!props.requireSellingStrategy) return true

            // fields must be have value
            if (inputValues.sellingStrategyType === undefined || inputValues.sellingStrategyValue === undefined) return false
            // discount between 0 and 100%
            if (
                inputValues.sellingStrategyType?.id === "discount" &&
                !(
                    inputValues.sellingStrategyValue >= 0 &&
                    inputValues.sellingStrategyValue < 100
                )
            ) return false
            // costGRP must not be negative
            if (
                inputValues.sellingStrategyType?.id === "costgrp" &&
                !(inputValues.sellingStrategyValue > 0)
            ) return false

            return true
        }

        const setDates = (startDate: number, endDate: number) => {
            setInputValues({ ...inputValues, startDate: startDate, endDate: endDate });
            dispatch(setInputValue({ key: "startDate", value: startDate }));
            dispatch(setInputValue({ key: "endDate", value: endDate }));
        }

        return (
            <Form onSubmit={onFormSubmit}>
                <h5 className="generalSectionCategory"><b>{t("txt_periodicite")}</b></h5>
                <Row>
                    <Input required title={t("txt_broadcast_period")}>
                        <PeriodPicker
                            startDate={inputValues.startDate}
                            endDate={inputValues.endDate}
                            setDates={setDates}
                            setStartDate={(date) => setValues(date, "startDate")}
                            setEndDate={(date) => setValues(date, "endDate")}
                        />
                    </Input>
                </Row>
                <Row>
                    <Input title={t("txt_broadcast_days")}>
                        <DaysPicker
                            selectedDays={inputValues.selectedDays}
                            setSelectedDays={(days) => setValues(days, "selectedDays")} />
                    </Input>
                </Row>
                <h5 className="generalSectionCategory"><b>{t("budget")}</b></h5>
                <Row>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_currency")}>
                            <CurrencyPicker
                                setSelectedCurrency={(currency) => setValues(currency, "selectedCurrency")}
                                retrieveCurrenciesUrl={props.retrieveCurrenciesUrl}
                                retrieveCurrenciesBody={props.retrieveCurrenciesBody}
                                retrieveCurrenciesMethod={props.retrieveCurrenciesMethod}
                                retrieveCurrenciesParser={props.retrieveCurrenciesParser}
                                value={inputValues.selectedCurrency}
                            />
                        </Input>
                    </Col>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_net_budget")}>
                            <DefaultInput
                                onChange={(value: any) => setValues(parseFloat(value), "netBudget")}
                                type="number"
                                iconKey="currency"
                                placeholder={t("txt_net_budget")}
                            />
                        </Input>
                    </Col>
                </Row>
                {props.requireSellingStrategy && <Row>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_selling_strategy")}>
                            <SellingStrategyPicker
                                setSelectedSellingStrategy={(sellingStrategy) => setValues(sellingStrategy, "sellingStrategyType")}
                                value={inputValues.sellingStrategyType}
                            />
                        </Input>
                    </Col>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_value")}>
                            <DefaultInput
                                onChange={(value: any) => setValues(parseFloat(value), "sellingStrategyValue")}
                                type="number"
                                iconKey="currency"
                                placeholder={t("txt_value")}
                            />
                        </Input>
                    </Col>
                </Row>}
                <h5 className="generalSectionCategory"><b>{t("txt_agency")}/{t("annoncer")}</b></h5>
                <Row>
                    <Col className="multipleInputsRow">
                        <Input required={userDetails?.qualified && !isDirectAdvertiser} title={t("txt_agency")}>
                            <AgencyPicker
                                allowNew={!userDetails?.qualified}
                                setSelectedAgency={(agency) => setValues(agency, "selectedAgency")}
                                retrieveAgenciesUrl={props.retrieveAgenciesUrl}
                                retrieveAgenciesBody={props.retrieveAgenciesBody}
                                retrieveAgenciesMethod={props.retrieveAgenciesMethod}
                                retrieveAgenciesParser={props.retrieveAgenciesParser}
                                value={inputValues.selectedAgency}
                                filter={userDetails?.agencies}
                                disabled={ isDirectAdvertiser || (userDetails?.agencies && userDetails.agencies.length === 0 && userDetails?.advertisers && userDetails.advertisers.length > 0)}
                            />
                        </Input>
                    </Col>
                    <Col className="multipleInputsRow">
                        <Input required title={t("annoncer")}>
                            <AdvertiserPicker
                                allowNew={!userDetails?.qualified}
                                // disabled if 
                                // user has no agencies and advertisers and no agency is selected
                                // user has no agencies and no agency is selected
                                // should not be disabled if user has advertisers and no agency
                                disabled={
                                    userDetails && (
                                        (!isDirectAdvertiser && userDetails.agencies.length === 0 && userDetails.advertisers.length === 0 && !inputValues.selectedAgency) ||
                                        (userDetails.agencies.length > 0 && !inputValues.selectedAgency)
                                    )
                                }
                                setSelectedAdvertiser={(advertiser) => setValues(advertiser, "selectedAdvertiser")}
                                retrieveAdvertisersUrl={
                                    (isDirectAdvertiser || inputValues.selectedAgency) && props.retrieveAdvertisersMethod === "get"
                                        ? StringReplacer.ReplaceInString(props.retrieveAdvertisersUrl, "{{", "}}", false, inputValues.selectedAgency ? inputValues.selectedAgency.id : "-1")
                                        : props.retrieveAdvertisersUrl
                                }
                                retrieveAdvertisersBody={props.retrieveAdvertisersBody}
                                retrieveAdvertisersMethod={props.retrieveAdvertisersMethod}
                                retrieveAdvertisersParser={props.retrieveAdvertisersParser}
                                value={inputValues.selectedAdvertiser}
                                filter={userDetails?.advertisers}
                            />
                        </Input>
                        <Input title="">
                            <Form.Check
                                type="checkbox"
                                id='directAdvertiserCheck'
                                label={t("txt_direct_advertiser")}
                                checked={isDirectAdvertiser}
                                onChange={onDirectAdvertiserClick}
                                className="directAdvertiserCheck"
                            />
                        </Input>
                    </Col>
                </Row>
                <Row>
                    <Col className="multipleInputsRow">
                        <Input required title={t("product")}>
                            <ProductPicker
                                allowNew={!userDetails?.qualified}
                                disabled={!inputValues.selectedAdvertiser}
                                setSelectedProduct={(product) => {
                                    setValues(product, "selectedProduct");
                                }}
                                retrieveProductsUrl={
                                    inputValues.selectedAdvertiser && props.retrieveProductsMethod === "get"
                                        ? StringReplacer.ReplaceInString(props.retrieveProductsUrl, "{{", "}}", false, inputValues.selectedAdvertiser.id)
                                        : props.retrieveProductsUrl
                                }
                                retrieveProductsBody={
                                    inputValues.selectedAdvertiser && props.retrieveProductsMethod === "post"
                                        ? JSON.parse(StringReplacer.ReplaceInString(JSON.stringify(props.retrieveProductsBody), "{{", "}}", false, inputValues.selectedAdvertiser ? inputValues.selectedAdvertiser.id : ""))
                                        : props.retrieveProductsBody
                                }
                                retrieveProductsMethod={props.retrieveProductsMethod}
                                retrieveProductsParser={props.retrieveProductsParser}
                                value={inputValues.selectedProduct}
                            />
                        </Input>
                    </Col>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_sector")}>
                            <SectorPicker
                                setSelectedSector={(sector) => setValues(sector, "selectedSector")}
                                retrieveSectorsParser={props.retrieveSectorsParser}
                                retrieveSectorsBody={props.retrieveSectorsBody}
                                retrieveSectorsUrl={props.retrieveSectorsUrl}
                                retrieveSectorsMethod={props.retrieveSectorsMethod}
                                value={inputValues.selectedSector}
                            />
                        </Input>
                    </Col>
                </Row>
                <h5 className="generalSectionCategory"><b>{t("supports")}</b></h5>
                {props.useCountryIdToRetrieveMediaChannels &&
                    <Row>
                        <Input required title={t("txt_country_label")}>
                            <CountryPicker
                                setSelectedCountry={(country) => setValues(country, "selectedCountry")}
                                retrieveCountriesUrl={props.retrieveCountriesUrl}
                                retrieveCountriesBody={props.retrieveCountriesBody}
                                retrieveCountriesMethod={props.retrieveCountriesMethod}
                                retrieveCountriesParser={props.retrieveCountriesParser}
                                value={inputValues.selectedCountry}
                            />
                        </Input>
                    </Row>
                }
                <Row>
                    <Input required title={t("supports")}>
                        <MediaChannelPicker
                            disabled={props.useCountryIdToRetrieveMediaChannels && !inputValues.selectedCountry}
                            setSelectedMediaChannels={(mediaChannels) => setValues(mediaChannels, "selectedMediaChannels")}
                            retrieveMediaChannelsUrl={props.retrieveMediaChannelsUrl}
                            retrieveMediaChannelsBody={!props.useCountryIdToRetrieveMediaChannels ? props.retrieveMediaChannelsBody : JSON.parse(StringReplacer.ReplaceInString(JSON.stringify(props.retrieveMediaChannelsBody), "{{", "}}", false, inputValues.selectedCountry ? inputValues.selectedCountry.id : ""))}
                            retrieveMediaChannelsMethod={props.retrieveMediaChannelsMethod}
                            retrieveMediaChannelsParser={props.retrieveMediaChannelsParser}
                            value={inputValues.selectedMediaChannels}
                        />
                    </Input>
                </Row>
                <h5 className="generalSectionCategory"><b>{t("txt_other_infos")}</b></h5>
                <Row>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_target")}>
                            <TargetPicker
                                setSelectedTarget={(target) => setValues(target, "selectedTarget")}
                                retrieveTargetsParser={props.retrieveTargetsParser}
                                retrieveTargetsBody={props.retrieveTargetsBody}
                                retrieveTargetsUrl={props.retrieveTargetsUrl}
                                retrieveTargetsMethod={props.retrieveTargetsMethod}
                                value={inputValues.selectedTarget}
                            />
                        </Input>
                    </Col>
                    <Col className="multipleInputsRow">
                        <Input required title={t("txt_format")}>
                            <FormatPicker
                                required
                                setInputValue={setValues}
                            />
                        </Input>
                    </Col>
                </Row>
                <Col className="text-center">
                    <Button
                        type="submit"
                        className="sectionContinueButton"
                        variant="dark"
                        disabled={props.nextButtonDisabled}>
                        {t("next_button")}
                    </Button>
                </Col>
            </Form>
        );
    }
}