import { useTranslation } from "react-i18next";
import { DataTableV2, DataTableV2DataHeader, DataTableV2DataRow } from "../Inputs/DataTableV2";

export interface MediaChannelDateTabProps {
    data: DataTableV2DataRow[];
    onCellEdit: (rowIdx: number, key: string, value: string) => void;
}

export const MediaChannelDateTab = (props: MediaChannelDateTabProps) => {
    const { t } = useTranslation();
    const headers: DataTableV2DataHeader[] = [
        { name: "", dataKey: "label", showHeader: false },
        { name: t("txt_no_spot_before"), dataKey: "noAllocationBefore", showHeader: true },
        { name: t("txt_no_spot_after"), dataKey: "noAllocationAfter", showHeader: true },
        { name: t("txt_campaign_start_hour"), dataKey: "campaignStartTime", showHeader: true },
        { name: t("txt_campaign_end_hour"), dataKey: "campaignEndTime", showHeader: true },
        { name: t("txt_max_spot_day"), dataKey: "minAllocationPerDay", showHeader: true },
        { name: t("txt_min_spot_day"), dataKey: "maxAllocationPerDay", showHeader: true },
        { name: t("txt_spot_spaced"), dataKey: "allocationOffsetMinutes", showHeader: true },
    ]

    return (
        <>
            <DataTableV2
                headers={headers}
                data={props.data}
                editValue={props.onCellEdit}
            />
        </>
    )
}