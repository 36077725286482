import './DataTableCellPicker.css'
import { Form } from "react-bootstrap"


export interface PickerItem {
    value: string,
    label: string
}

export interface DataTableCellPickerProps {
    pickerOptions: PickerItem[];
    onChange: any;
    value?: string;
}


export const DataTableCellPicker = (props: DataTableCellPickerProps) => {
    return (
        <Form.Select className="dataTableCellPicker" aria-label="cell select" value={props.value} onChange={props.onChange}>
            {props.pickerOptions.map((item, index) => <option key={index} value={item.value} className='select-option'>{item.label}</option>)}
        </Form.Select>
    )
}