import { IconDefinition, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import './ChoicePicker.css';
import React from "react";
import { useTranslation } from "react-i18next";


export interface ChoicePickerOption {
    id: string;
    name: string;
}

interface ChoicePickerProps {
    setSelectedData: (data: any) => void;
    placeholder: string;
    displayCode?: boolean;
    data: ChoicePickerOption[];
    multiple?: boolean;
    disabled?: boolean;
    icon?: IconDefinition;
    required?: boolean;
    controlId: string;
    value: any[];
    allowNew?: boolean;
}

export const ChoicePicker = (props: ChoicePickerProps) => {
    const { t } = useTranslation();
    const typeaheadRef = React.useRef<any>(null);

    React.useEffect(() => {
        if (props.required && typeaheadRef.current) {
            if (props.value.length === 0) {
                typeaheadRef.current.getInput().required = true;
            }
            else 
                typeaheadRef.current.getInput().required = false;
        }
    }, [typeaheadRef, props.required, props.value]);

    React.useEffect(() => {
        if (props.data && props.data.length === 1 && props.value.length === 0) {
            if (!props.multiple)
                props.setSelectedData(props.data[0]);
            else
                props.setSelectedData([props.data[0]])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const onSelected = (selected: any[]) => {
        if (props.multiple) {
            props.setSelectedData(selected);
        }
        else {
            props.setSelectedData(selected[0]);
        }
    }

    return (
        <Form.Group controlId={props.controlId}>
            <InputGroup className="mb-3">
                <InputGroup.Text className="inputIcon"><FontAwesomeIcon icon={props.icon ? props.icon : faSearch} /></InputGroup.Text>
                <Typeahead
                    allowNew={props.allowNew}
                    ref={typeaheadRef}
                    disabled={props.disabled}
                    className="choicePicker"
                    id="choicePicker"
                    options={props.data}
                    multiple={props.multiple}
                    labelKey={
                        props.allowNew ? "name" :
                        ((option: any) => {
                            return `${option.name} ${props.displayCode ? `(${option.code})` : ""}`
                        })
                    }
                    placeholder={props.placeholder}
                    onChange={onSelected}
                    emptyLabel=""
                    newSelectionPrefix={t("txt_new") + " : "}
                    clearButton
                    selected={props.value}
                />
            </InputGroup>
        </Form.Group>
    );
}