import { Button } from "react-bootstrap";
import { ApiClientManager } from "../Business/api/ApiClientManager";
import { AllocationApiClient } from "../Business/api/AllocationApiClient";
import React from "react";
import { socket } from "../socket";

export const TestSocket = () => {
    const [allocationResults, setAllocationResults] = React.useState<any[]>([]);

    React.useEffect(() => {
        function onTestSocket(value: any) {
            setAllocationResults(value.data.data)
        }

        socket.on("allocationResults", onTestSocket);

        return () => {
            socket.off("allocationResults", onTestSocket);
        };
    }, []);

    const testSocket = () => {
        const token = localStorage.getItem("token");

        if (token)
            ApiClientManager.getInstance().getApiClient<AllocationApiClient>("AllocationApiClient").StartAllocation(token, "testSessionRunId");
    }

    return (
        <div>
            <Button onClick={testSocket}>TestSocket</Button>
            {allocationResults.map((result, index) => (
                <div key={index}>
                    {JSON.stringify(result)}
                </div>
            ))}
        </div>
    );    
}