import React from "react";
import { Row, Container, Pagination, Col, Button, Spinner } from "react-bootstrap";
import "./AllocatorSolutions.css";
import { AllocatorSolutionKPIs } from "./AllocatorSolutionKPIs";
import { AllocatorSolutionCalendar } from "./AllocatorSolutionCalendar";
import { useTranslation } from "react-i18next";
import { AllocationSolution, AllocationResult, AllocatedScreen } from "../Business/dto/AllocationSolution";
import { useLocation, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { BriefDTO } from "../Business/dto/Brief";
import { ApiClientManager } from "../Business/api/ApiClientManager";
import { BriefApiClient } from "../Business/api/BriefApiClient";
import { useAppSelector } from "../Redux/hooks";
import { AllocationApiClient } from "../Business/api/AllocationApiClient";
import { toast } from "react-toastify";
import { Page } from "./Page";

export const Colors = [
    "blue",
    "green",
    "red",
    "yellow",
    "orange",
    "purple",
    "pink",
    "brown",
    "grey",
    "black"
]

export enum SolutionView {
    BySupport = "BySupport",
    Global = "Global",
    BySupportByDayPart = "BySupportByDayPart",
    ByFormat = "ByFormat",
    ByFormatByPeriod = "ByFormatByPeriod"
}

export interface MediaChannelData {
    channel: string;
    screens: AllocatedScreen[];
    grp: number;
    targetBudget: number;
    budget: number;
    availableDayParts: { dayPartLabel: string, targetBudget: number, budget: number, screens: AllocatedScreen[] }[];
}

export interface DurationData {
    duration: number;
    targetRepartition?: number;
    grpRepartition: number;
    screens: AllocatedScreen[];
    periods: { period: string, targetGrpRepartition: number, grpRepartition: number, screens: AllocatedScreen[] }[];
}

export const AllocatorSolutions = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const { sessionRunId } = useParams();
    const userDetails = useAppSelector((state) => state.user.details);
    const accessToken = useAppSelector((state) => state.auth.accessToken);
    const [solutions, setSolutions] = React.useState<AllocationSolution[]>([]);
    const [allocationResult, setAllocationResult] = React.useState<AllocationResult>();
    const [selectedSolutionIdx, setSelectedSolutionIdx] = React.useState<number>(0);
    const [selectedView, setSelectedView] = React.useState<SolutionView>(SolutionView.Global);
    // Is the selected element, such as a support or a format or a daypart
    const [selectedElementIdx, setSelectedElementIdx] = React.useState<number>(0);
    const [startDate, setStartDateKPI] = React.useState<string>("");
    const [endDate, setEndDateKPI] = React.useState<string>("");
    const [mediaChannelData, setMediaChannelData] = React.useState<MediaChannelData[]>([]);
    const [durationData, setDurationData] = React.useState<DurationData[]>([]);
    const [showFormatView, setShowFormatView] = React.useState<boolean>(false);
    const [showFormatPeriodView, setShowFormatPeriodView] = React.useState<boolean>(false);
    const [showDaypartView, setShowDaypartView] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [brief, setBrief] = React.useState<BriefDTO>();
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const [userSelectedSolution, setUserSelectedSolution] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (state) {
            ApiClientManager.getInstance().getApiClient<BriefApiClient>(BriefApiClient.key).GetBrief(state.briefId, accessToken).then((brief) => {
                setBrief(brief);
            });

            const tmpSolutions: AllocationSolution[] = [];
            console.log(sessionRunId)
            if (state.solutions) {
                Object.keys(state.solutions.solutions).forEach((key) => {
                    const solution = state.solutions.solutions[key];
                    tmpSolutions.push({
                        allocations: solution.allocations,
                        kpis: solution.kpis
                    });
                });

                setAllocationResult(state.solutions);
            }
            else if (sessionRunId) {
                console.log(sessionRunId)
                ApiClientManager.getInstance().getApiClient<AllocationApiClient>(AllocationApiClient.key).GetAllocationResults(accessToken, sessionRunId).then((res) => {
                    Object.keys(res.solutions).forEach((key) => {
                        const solution = res.solutions[key];
                        tmpSolutions.push({
                            allocations: solution.allocations,
                            kpis: solution.kpis
                        });
                    });

                    setAllocationResult(res);
                });
            }

            setSolutions(tmpSolutions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, sessionRunId])

    React.useEffect(() => {
        if (brief && brief.selectedMediaPlanIdx !== undefined) {
            setUserSelectedSolution(true);
            setSelectedSolutionIdx(brief.selectedMediaPlanIdx);
        }
    }, [brief])

    // Initialization hook
    React.useEffect(() => {
        if (allocationResult && solutions && solutions.length > 0) {
            setLoading(true);

            const tmpSelectedSolution = solutions[selectedSolutionIdx];
            const tmpMediaChannelData: MediaChannelData[] = [];
            const tmpDurationData: DurationData[] = [];

            // Init duration data
            // Use grp_per_period if it exists, otherwise use grp_per_format
            if (tmpSelectedSolution.kpis.grp_per_period) {
                for (let i = 0; i < tmpSelectedSolution.kpis.grp_per_period.target.length; i++) {
                    const target = tmpSelectedSolution.kpis.grp_per_period.target[i];
                    const res = tmpSelectedSolution.kpis.grp_per_period.result[i];

                    if (!tmpDurationData.find(d => d.duration === target.format)) {
                        tmpDurationData.push({
                            duration: target.format,
                            screens: [],
                            periods: [],
                            grpRepartition: 0
                        });
                    }

                    const duration = tmpDurationData.find(d => d.duration === target.format);

                    if (duration) {
                        duration.periods.push({
                            period: target.period,
                            screens: [],
                            targetGrpRepartition: target.target,
                            grpRepartition: res
                        });
                    }
                }

                setShowFormatPeriodView(true);
            }
            else if (tmpSelectedSolution.kpis.grp_per_format) {
                setShowFormatPeriodView(false);

                for (let i = 0; i < tmpSelectedSolution.kpis.grp_per_format.target.length; i++) {
                    const target = tmpSelectedSolution.kpis.grp_per_format.target[i];
                    const res = tmpSelectedSolution.kpis.grp_per_format.result[i];

                    if (!tmpDurationData.find(d => d.duration === target.format)) {
                        tmpDurationData.push({
                            duration: target.format,
                            screens: [],
                            periods: [],
                            targetRepartition: target.value,
                            grpRepartition: res
                        });
                    }
                }

                if (tmpSelectedSolution.kpis.grp_per_format.target.length === 1)
                    setShowFormatView(false);
                else
                    setShowFormatView(true);
            }
            else {
                setShowFormatView(false);
                setShowFormatPeriodView(false);
            }

            // Init media channel data
            for (let i = 0; i < tmpSelectedSolution.kpis.cost_per_station.target.length; i++) {
                const target = tmpSelectedSolution.kpis.cost_per_station.target[i];
                const res = tmpSelectedSolution.kpis.cost_per_station.result[i];

                if (!tmpMediaChannelData.find(d => d.channel === target.channel)) {
                    tmpMediaChannelData.push({
                        channel: target.channel,
                        screens: [],
                        grp: 0,
                        targetBudget: target.target_budget,
                        budget: res,
                        availableDayParts: []
                    });
                }
            }

            if (tmpSelectedSolution.kpis.cost_per_daypart) {
                for (let i = 0; i < tmpSelectedSolution.kpis.cost_per_daypart.target.length; i++) {
                    const target = tmpSelectedSolution.kpis.cost_per_daypart.target[i];
                    const res = tmpSelectedSolution.kpis.cost_per_daypart.result[i];

                    const channelData = tmpMediaChannelData.find(d => d.channel === target.channel);

                    if (channelData) {
                        channelData.availableDayParts.push({
                            dayPartLabel: target.daypart,
                            targetBudget: target.target_budget,
                            budget: res,
                            screens: []
                        });
                    }
                }
                setShowDaypartView(true);
            }
            else
                setShowDaypartView(false);

            for (let i = 0; i < tmpSelectedSolution.allocations.length; i++) {
                const allocation = tmpSelectedSolution.allocations[i];

                if (allocation.normal > 0) {
                    const correspondingScreen = allocationResult.inventory[i];
                    const screenMediaChannelData = tmpMediaChannelData.find(d => d.channel === correspondingScreen.media_channel);
                    const screenDurationData = tmpDurationData.find(d => d.duration === allocation.normal);
                    const screenDayPart = correspondingScreen.daypart;
                    const screenDayPartData = screenMediaChannelData?.availableDayParts.find(d => d.dayPartLabel === screenDayPart);
                    const toPush: AllocatedScreen = {
                        date: correspondingScreen.date,
                        time: correspondingScreen.start_time,
                        channel: correspondingScreen.media_channel,
                        audience: correspondingScreen.audience,
                        duration: allocation.normal
                    };

                    if (screenMediaChannelData) {
                        screenMediaChannelData.screens.push(toPush);
                        screenMediaChannelData.grp += correspondingScreen.audience;

                        if (screenDayPartData)
                            screenDayPartData.screens.push(toPush);
                    }

                    if (!screenDurationData) {
                        tmpDurationData.push({
                            duration: allocation.normal,
                            screens: [toPush],
                            periods: [],
                            grpRepartition: 0
                        });
                    }
                    else {
                        screenDurationData.screens.push(toPush);

                        const periods = screenDurationData.periods.map(p => p.period);

                        periods.forEach((period, idx) => {
                            const start = parseInt(period.split("~")[0]);
                            const end = parseInt(period.split("~")[1]);

                            if (toPush.date >= start && toPush.date <= end)
                                screenDurationData.periods[idx].screens.push(toPush);
                        });
                    }
                }
            }

            setDurationData(tmpDurationData);
            setMediaChannelData(tmpMediaChannelData);
            setSelectedElementIdx(0);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSolutionIdx, solutions, allocationResult])

    React.useEffect(() => {
        setSelectedSolutionIdx(0);
    }, [solutions])

    React.useEffect(() => {
        setSelectedElementIdx(0);
    }, [selectedView])

    const handlePaginationItemClick = (event: any) => {
        if (event.target.text) {
            const index = parseInt(event.target.text.split(" ")[1]) - 1;
            setSelectedSolutionIdx(index);
        }
    }

    const generatePaginationItem = (_item: any, index: number, disabled: boolean) => {
        return (
            <Pagination.Item
                disabled={disabled}
                key={"editop-" + (index + 1)}
                active={index === selectedSolutionIdx}
                onClick={handlePaginationItemClick}>
                {t("txt_proposal")} {index + 1}
            </Pagination.Item>
        );
    }

    const idLibSelector = (obj: any) => {
        if (!obj) {
            return ({
                id: "-1",
                lib: ""
            })
        }

        return ({
            id: obj.id,
            lib: obj.name
        })
    }

    const onNextButtonClick = async () => {
        const inventory = allocationResult?.inventory;
        const allocations: any[] = [];

        if (inventory && brief) {
            setButtonLoading(true);
            solutions[selectedSolutionIdx].allocations.forEach((allocation: any, idx) => {
                if (allocation.normal > 0) {
                    const spots: any[] = [];
                    Object.keys(allocation).forEach((key) => {
                        spots.push({
                            position: key,
                            format: allocation[key]
                        })
                    });

                    allocations.push({
                        ...inventory[idx],
                        spots: spots
                    })
                }
            });


            // ENVOYER ALLOCATIONS ET BRIEF ID A L'API PLUTOT POUR TOUT FAIRE LA BAS AVANT L'ENVOI
            const toSend = {
                allocations: allocations,
                id: state.briefId,
                query: {
                    target: idLibSelector(brief.brief.Target),
                    agency: idLibSelector(brief.brief.Agency),
                    advertiser: idLibSelector(brief.brief.Advertiser),
                    product: idLibSelector(brief.brief.Product),
                    sector: idLibSelector(brief.brief.Sector),
                    user: {
                        id: "-1",
                        lib: userDetails?.accountAdminEmail
                    }
                }
            }

            try {
                const res = await ApiClientManager.getInstance().getApiClient<AllocationApiClient>(AllocationApiClient.key).PushSelectedSolution(
                    accessToken,
                    brief.allocatorSessionRunId ? brief.allocatorSessionRunId : "",
                    toSend,
                    selectedSolutionIdx
                );
                console.log("success")
                console.log(res)
                toast.success(`${t("txt_solution_selected2")}\n${t("txt_toast_select_solution_msg")}`);
                setBrief({ ...brief, selectedMediaPlanIdx: selectedSolutionIdx });
                setUserSelectedSolution(true);
            }
            catch (err) {
                toast.error(t("txt_error_occurred"));
                console.log(err)
            }
            finally {
                setButtonLoading(false);
            }
        }
    }

    return (
        <>
            {brief &&
                <Page category={"txt_proposals"} title={brief.selectedMediaPlanIdx === undefined ? "txt_select_proposals" : "txt_you_selected_proposal"} authenticated>
                    <Row>
                        <Container>
                            <Row>
                                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Pagination className="allocatorSolutionsPagination">
                                        {solutions.map((item, index) => generatePaginationItem(item, index, brief.selectedMediaPlanIdx !== undefined))}
                                    </Pagination>
                                </Col>
                            </Row>
                            <Container>
                                {loading ?
                                    <div className="solutionLoader">
                                        <PulseLoader
                                            aria-label="Loading"
                                        />
                                    </div>
                                    :
                                    <>
                                        <Row className="solutionPresentation">
                                            <Col className="solutionPresentationCol" xs={12} md={3}>
                                                <AllocatorSolutionKPIs
                                                    solutionNumber={selectedSolutionIdx}
                                                    solution={solutions[selectedSolutionIdx]}
                                                    solutionView={selectedView}
                                                    showFormatPeriodView={showFormatPeriodView}
                                                    showFormatView={showFormatView}
                                                    showDayPartView={showDaypartView}
                                                    setSolutionView={setSelectedView}
                                                    mediaChannelData={mediaChannelData}
                                                    durationData={durationData}
                                                    selectedElementIdx={selectedElementIdx}
                                                    setSelectedElementIdx={setSelectedElementIdx}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    brief={brief}
                                                />
                                            </Col>
                                            {<Col xs={12} md={9} className="solutionCalendar">
                                                <AllocatorSolutionCalendar
                                                    solution={solutions[selectedSolutionIdx]}
                                                    solutionView={selectedView}
                                                    mediaChannelData={mediaChannelData}
                                                    durationData={durationData}
                                                    selectedElementIdx={selectedElementIdx}
                                                    setStartDateKPI={setStartDateKPI}
                                                    setEndDateKPI={setEndDateKPI}
                                                />
                                            </Col>}
                                        </Row>
                                        <Row>
                                            <Col className="text-center">
                                                <Button disabled={buttonLoading || userSelectedSolution} onClick={onNextButtonClick} className="selectSolutionButton">
                                                    {buttonLoading ? <Spinner animation="border" role="status" size="sm" /> : t("next_button")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Container>
                        </Container>
                    </Row>
                </Page>
            }
        </>
    );
};