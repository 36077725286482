import { Card, Container } from 'react-bootstrap';

interface SectionBodyProps {
    children: any;
    expanded: boolean;
    idx: number;
    onChangeSection: (sectionIdx: number) => void;
}

export const SectionBody = (props: SectionBodyProps) => {
    return (
        <Card.Body className={`sectionContent ${props.expanded ? "expanded" : "collapsed"}`}>
            <Container>
                {props.children}
            </Container>
        </Card.Body>
    );
}