import { Button, Col, Form, Row } from "react-bootstrap";
import { Section } from "./Section";
import { Input } from "../Inputs/Input";
import React from "react";
import { useAppDispatch } from '../../Redux/hooks';
import { useAppSelector } from "../../Redux/hooks";
import { setInputValue } from '../../Redux/Reducers/briefSlice';
import { DataTableV2, DataTableV2DataHeader, DataTableV2DataRow } from "../Inputs/DataTableV2";
import { useTranslation } from "react-i18next";
import { dateObjectToNumber, formatDateTime, numberToDateObject } from "../../Business/utils/DateUtils";
import { BriefDTO } from "../../Business/dto/Brief";

interface FormatSectionProps {
    idx: number;
    expanded: boolean;
    optionnalGrpPerFormatPerPeriod: boolean;
    onChangeSection: (sectionIdx: number) => void;
    setSectionValidated: (sectionIdx: number, validated: boolean) => void;
    nextButtonDisabled: boolean;
    briefToLoad?: BriefDTO;
}

interface grpPerFormat {
    [key: string]: any;
    format: string;
    grpRepartitionPercent: number;
}

interface grpPerFormatPerPeriod {
    [key: string]: any;
    dateStart: number;
    dateEnd: number;
    grpRepartitionPercent: number;
    formatRepartition: formatRepartition[];
}

interface formatRepartition {
    [key: string]: any;
    format: string;
    repartition: number;
}

export class FormatSection extends Section<FormatSectionProps> {
    Name = "txt_duration_criteria";


    SectionBody = (props: FormatSectionProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const grpPerFormatFromGeneral = useAppSelector(state => state.brief.inputData.grpPerFormat);
        const globalStartDate = useAppSelector(state => state.brief.inputData.startDate);
        const [tmpStartDate, setTmpStartDate] = React.useState<number>(0);
        const endDate = useAppSelector(state => state.brief.inputData.endDate);
        const [isSectionValidated, setIsSectionValidated] = React.useState<boolean>(true);
        const [dataGrpPerFormat, setDataGrpPerFormat] = React.useState<grpPerFormat[]>([]);
        const [dataGrpPerFormatErrors, setDataGrpPerFormatErrors] = React.useState<string[]>([]);
        const [dataGrpPerFormatPerPeriod, setDataGrpPerFormatPerPeriod] = React.useState<grpPerFormatPerPeriod[]>([]);
        const [dataGrpPerFormatPerPeriodErrors, setDataGrpPerFormatPerPeriodErrors] = React.useState<string[]>([]);
        const [isErrorDisplayed, setIsErrorDisplayed] = React.useState<boolean>(false);

        React.useEffect(() => {
            setTmpStartDate(globalStartDate);
        }, [globalStartDate]);

        // Method to transform grpPerFormat to DataTableV2DataRow
        const transformToDataTableRow = (grpPerFormat: grpPerFormat[]): DataTableV2DataRow[] => {
            return grpPerFormat.map((grp) => {
                let row: DataTableV2DataRow = {};
                row.format = {
                    value: grp.format || "",
                    editable: false
                };
                row.grpRepartitionPercent = {
                    value: grp.grpRepartitionPercent.toString() || "",
                    editable: !props.optionnalGrpPerFormatPerPeriod,
                    type: "percentage"
                };
                return row;
            });
        }

        // Method to transform grpPerFormatPerPeriod to DataTableV2DataRow
        const transformToDataTableRowPerPeriod = (grpPerFormatPerPeriod: grpPerFormatPerPeriod[]): DataTableV2DataRow[] => {
            return grpPerFormatPerPeriod.map((grp, index) => {
                let startDate = grp.dateStart;
                let endDate = grp.dateEnd;
                let row: DataTableV2DataRow = {}
                row.period = {
                    value: "",
                    editable: true,
                    type: "date",
                    maxDate: endDate,
                    startDate: startDate,
                    endDate: endDate,
                    setStartDate: (date: number) => {
                        startDate = date;
                    },
                    setEndDate: (date: number) => {
                        let end = date;
                        let newData: grpPerFormatPerPeriod[] = [...dataGrpPerFormatPerPeriod];
                        newData[index] = { ...newData[index], dateStart: startDate, dateEnd: end };
                        setDataGrpPerFormatPerPeriod(newData);

                        // When adding another row in GrpPerFormatPerPeriod, start date will be set to precedent end date + 1
                        let tempDate = numberToDateObject(date);
                        tempDate.setDate(tempDate.getDate() + 1);
                        let tempDateInt = dateObjectToNumber(tempDate);
                        setTmpStartDate(tempDateInt > endDate ? date :  tempDateInt);
                        
                        dispatch(setInputValue({ key: "grpPerFormatPerPeriod", value: newData }));
                    }
                };
                row.grpRepartitionPercent = {
                    value: grp.grpRepartitionPercent.toString() || "",
                    editable: true,
                    type: "percentage"
                };
                grp.formatRepartition.forEach((f) => {
                    row[f.format] = {
                        value: f.repartition.toString() || "",
                        editable: true,
                        type: "percentage"
                    }
                });
                row.total = {
                    value: grp.formatRepartition.reduce((acc, f) => acc + f.repartition, 0).toString(),
                    editable: false
                };
                return row;
            });
        };


        const format: string[] = useAppSelector(state => state.brief.inputData.grpPerFormat)?.map((f: grpPerFormat) => f.format) || [];

        const headersGrpPerFormat: DataTableV2DataHeader[] = [
            { name: t("txt_format"), dataKey: "format", showHeader: true },
            { name: "GRP (%)", dataKey: "grpRepartitionPercent", total: true, showHeader: true }
        ];
        const headersGrpPerFormatPerPeriod: DataTableV2DataHeader[] = [
            { name: t("txt_period_short"), dataKey: "period", showHeader: true },
            { name: "GRP (%)", dataKey: "grpRepartitionPercent", showHeader: true },
            ...format.map((f) => {
                return {
                    name: f,
                    dataKey: f,
                    showHeader: true
                }
            }),
            { name: "Total", dataKey: "total", showHeader: true },
        ];

        const [grpPerFormatTable, setGrpPerFormatTable] = React.useState<DataTableV2DataRow[]>([
            ...transformToDataTableRow(dataGrpPerFormat)
        ]);

        const [grpPerFormatPerPeriodTable, setGrpPerFormatPerPeriodTable] = React.useState<DataTableV2DataRow[]>([
            ...transformToDataTableRowPerPeriod(dataGrpPerFormatPerPeriod)
        ]);

        React.useEffect(() => {
            const verificationResult = verifyAll();
            setIsSectionValidated(verificationResult);
            props.setSectionValidated(props.idx, verificationResult);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dataGrpPerFormat, dataGrpPerFormatPerPeriod]);

        React.useEffect(() => {
            if (grpPerFormatFromGeneral) {
                setDataGrpPerFormat(grpPerFormatFromGeneral);
            }
        }, [grpPerFormatFromGeneral]);

        React.useEffect(() => {
            let newFormat: string[] = [];
            dataGrpPerFormat.forEach((grp) => {
                if (grp.format && !newFormat.includes(grp.format)) {
                    newFormat.push(grp.format);
                }
            });
            setGrpPerFormatTable(transformToDataTableRow(dataGrpPerFormat));
            if (!props.optionnalGrpPerFormatPerPeriod) {
                setDataGrpPerFormatPerPeriod([]);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dataGrpPerFormat]);

        React.useEffect(() => {
            let newGrpPerFormat: grpPerFormat[] = [];
            format.forEach((f) => {
                newGrpPerFormat.push({ format: f, grpRepartitionPercent: computeGrpPercentFromGrpPerFormatPerPeriod(dataGrpPerFormatPerPeriod, f) });
            });

            // If GrpPerFormatPerPeriod is empty, we fall back to the initial data
            if (dataGrpPerFormatPerPeriod.length === 0) {
                newGrpPerFormat = [];
                format.forEach((f) => {
                    newGrpPerFormat.push({ format: f, grpRepartitionPercent: 100 / format.length });
                });
            }
            setDataGrpPerFormat(newGrpPerFormat);
            dispatch(setInputValue({ key: "grpPerFormat", value: newGrpPerFormat }));
            setGrpPerFormatPerPeriodTable(transformToDataTableRowPerPeriod(dataGrpPerFormatPerPeriod));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dataGrpPerFormatPerPeriod]);

        const computeGrpPercentFromGrpPerFormatPerPeriod = (grpPerFormatPerPeriod: grpPerFormatPerPeriod[], format: string): number => {
            let total = 0;
            grpPerFormatPerPeriod.forEach((grp) => {
                grp.formatRepartition.forEach((f) => {
                    if (f.format === format) {
                        total += f.repartition * grp.grpRepartitionPercent / 100;
                    }
                });
            });
            return total;
        }

        const onCellEditGrpPerFormat = (rowIdx: number, key: string, value: string) => {
            // Allow errors to be displayed only if the user has written something
            if (!isErrorDisplayed) setIsErrorDisplayed(true);
            setIsSectionValidated(false);
            let newData: grpPerFormat[] = [...dataGrpPerFormat];
            newData[rowIdx] = { ...newData[rowIdx], [key]: value };
            setDataGrpPerFormat(newData);
            dispatch(setInputValue({ key: "grpPerFormat", value: newData }));
        }

        const onCellEditGrpPerFormatPerPeriod = (rowIdx: number, key: string, value: string) => {
            // Allow errors to be displayed only if the user has written something
            if (!isErrorDisplayed) setIsErrorDisplayed(true);
            setIsSectionValidated(false);
            let newData: grpPerFormatPerPeriod[] = [...dataGrpPerFormatPerPeriod];
            if (format.includes(key)) {
                newData[rowIdx] = {
                    ...newData[rowIdx], formatRepartition:
                        newData[rowIdx].formatRepartition.map((f) => {
                            if (f.format === key) {
                                return { ...f, repartition: parseInt(value || "0") }
                            }
                            return f;
                        })
                }
            }
            else {
                newData[rowIdx] = { ...newData[rowIdx], [key]: value };
            }
            setDataGrpPerFormatPerPeriod(newData);
            dispatch(setInputValue({ key: "grpPerFormatPerPeriod", value: newData }));
        }

        const deleteLineGrpPerFormatPerPeriod = (rowIdx: number) => {
            let newData = [...dataGrpPerFormatPerPeriod];
            newData.splice(rowIdx, 1);
            setDataGrpPerFormatPerPeriod(newData);
            dispatch(setInputValue({ key: "grpPerFormatPerPeriod", value: newData }));
        }

        const addLineGrpPerFormatPerPeriod = () => {
            let newData = [...dataGrpPerFormatPerPeriod];
            let grpRepartitionPercent = 100 - dataGrpPerFormatPerPeriod.reduce((acc, grp) => Number(acc) + Number(grp.grpRepartitionPercent), 0);
            newData.push(
                {
                    dateStart: tmpStartDate,
                    dateEnd: endDate,
                    grpRepartitionPercent: grpRepartitionPercent,
                    formatRepartition: format.map((f) => {
                        return {
                            format: f,
                            repartition: 100 / format.length
                        }
                    })
                }
            );
            setDataGrpPerFormatPerPeriod(newData);
            dispatch(setInputValue({ key: "grpPerFormatPerPeriod", value: newData }));
        }

        // Verify that the section is correctly filled
        const verifyAll = (): boolean => {
            const isVerifiedGrpPerFormat = verifyGrpPerFormat();
            const isVerifiedGrpPerFormatPerPeriod = verifyGrpPerFormatPerPeriod();
            return isVerifiedGrpPerFormat && isVerifiedGrpPerFormatPerPeriod;
        }

        // Verify that the sub section GRP per format is correctly filled, if not display an error message
        const verifyGrpPerFormat = (): boolean => {
            let totalGrpRepartition = 0;
            dataGrpPerFormat.forEach((grp) => {
                totalGrpRepartition += grp.grpRepartitionPercent;
            });
            let isValid: boolean = totalGrpRepartition === 100;
            !isValid && isErrorDisplayed ? setDataGrpPerFormatErrors([t("txt_error_format_section_GrpPerFormat")]) : setDataGrpPerFormatErrors([]);
            return isValid;
        }

        // Verify that the sub section GRP per format per period is correctly filled, if not display an error message
        const verifyGrpPerFormatPerPeriod = (): boolean => {
            let isValid: boolean = true;
            let errors: string[] = [];
            dataGrpPerFormatPerPeriod.forEach((grp) => {
                let total = 0;
                grp.formatRepartition.forEach((f) => {
                    total += f.repartition;
                });
                if (total !== 100 && isErrorDisplayed) {
                    errors.push(t("txt_error_format_section_GrpPerFormatPerPeriod") + formatDateTime(grp.dateStart) + " - " + formatDateTime(grp.dateEnd));
                }
            });
            isValid = errors.length === 0;
            setDataGrpPerFormatPerPeriodErrors(errors);
            return isValid;
        }

        const computeLastFormatCellGrpPerFormatPerPeriod = (rowIdx: number): void => {
            const newData = [...dataGrpPerFormatPerPeriod];
            let total = 0;
            for (let i = 0; i < newData[rowIdx].formatRepartition.length - 1; i++) {
                total += newData[rowIdx].formatRepartition[i].repartition;
            }
            newData[rowIdx] = {
                ...newData[rowIdx],
                formatRepartition: newData[rowIdx].formatRepartition.map((f, idx) => {
                    if (idx === newData[rowIdx].formatRepartition.length - 1) {
                        return { ...f, repartition: Math.min(Math.max(100 - total, 0), 100) };
                    }
                    return f;
                })
            }
            setDataGrpPerFormatPerPeriod(newData);
            dispatch(setInputValue({ key: "grpPerFormatPerPeriod", value: newData }));
        }


        const onFormSubmit = (event: any) => {
            event.preventDefault();
            props.onChangeSection(props.idx + 1);
        }

        const renderErrors = (errors: string[]) => {
            return errors.map((error) => {
                return (
                    <Row>
                        <Col style={{ color: "red" }}>{error}</Col>
                    </Row>
                );
            });
        }

        return (
            <Form onSubmit={onFormSubmit}>
                <Row>
                    <Input title={t("txt_grp_by_format")}>
                        <DataTableV2
                            headers={headersGrpPerFormat}
                            data={grpPerFormatTable}
                            editValue={onCellEditGrpPerFormat}
                        />
                    </Input>
                </Row>
                {renderErrors(dataGrpPerFormatErrors)}
                {props.optionnalGrpPerFormatPerPeriod &&
                    <>
                        <Row>
                            <Input title={t("txt_grp_by_format_by_period")}>
                                <DataTableV2
                                    headers={headersGrpPerFormatPerPeriod}
                                    data={grpPerFormatPerPeriodTable}
                                    deleteLine={deleteLineGrpPerFormatPerPeriod}
                                    addLine={addLineGrpPerFormatPerPeriod}
                                    editValue={onCellEditGrpPerFormatPerPeriod}
                                    onBlur={computeLastFormatCellGrpPerFormatPerPeriod}
                                />
                            </Input>
                        </Row>
                        {renderErrors(dataGrpPerFormatPerPeriodErrors)
                        }
                    </>
                }
                <Col className="text-center">
                    <Button type="submit" disabled={!isSectionValidated} className="sectionContinueButton" variant="dark">{t("next_button")}</Button>
                </Col>
            </Form>
        );
    }
}