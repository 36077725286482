export function resolveUrl(url1: string, url2: string) {
    let result = "";

    if (url1.endsWith("/"))
        url1 = url1.slice(0, -1);

    if (url2.startsWith("/"))
        url2 = url2.slice(1);

    result = url1 + "/" + url2;

    return (result);
}