export function formatDateTime(date: number): string {
    const dateSplit = date.toString().split("");
    return `${dateSplit[6]}${dateSplit[7]}/${dateSplit[4]}${dateSplit[5]}/${dateSplit[0]}${dateSplit[1]}${dateSplit[2]}${dateSplit[3]}`;
}

export function numberToDateObject(date: number): Date {

    // Convert the number to a string for easier manipulation
    const dateStr = date.toString();

    // Extract year, month, and day from the string
    const year = parseInt(dateStr.substring(0, 4));
    const month = parseInt(dateStr.substring(4, 6)) - 1; // Month is zero-based in JavaScript Date
    const day = parseInt(dateStr.substring(6, 8));

    // Create and return a new Date object
    return new Date(year, month, day);

}

export function dateObjectToNumber(date: Date): number {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    const day = date.getDate().toString().padStart(2, '0');
  
    // Combine into a single number
    const yyyymmdd = `${year}${month}${day}`;
  
    return parseInt(yyyymmdd);
  }

export function convertDateNumberToString(year: number, month: number, day: number, locale: string) {
    if (locale === "fr")
        return `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
    else
        return `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day}/${year}`;
}