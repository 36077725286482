import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Sector } from "../../Business/dto/Brief";
import { ExternalDataPicker } from "./ExternalDataPicker";
import React from 'react';
import { DataRetrieval } from '../../Business/dto/ExternalSystemRetrieveBody';
import { DataKey } from '../../Business/dto/ExternalSystemElement';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export interface SectorPickerProps {
    retrieveSectorsUrl: string;
    retrieveSectorsBody: any;
    retrieveSectorsMethod: "get" | "post";
    retrieveSectorsParser: any;
    setSelectedSector: (sector: Sector | undefined) => void;
    required?: boolean;
    value: Sector | undefined;
}

export const SectorPicker = (props: SectorPickerProps) => {
    const { t } = useTranslation();
    const [body, setBody] = React.useState<DataRetrieval | undefined>(undefined);

    React.useEffect(() => {
        setBody({
            endpoint: props.retrieveSectorsUrl,
            method: props.retrieveSectorsMethod,
            body: props.retrieveSectorsBody,
            parser: props.retrieveSectorsParser
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {body &&
                <ExternalDataPicker
                    setSelectedData={props.setSelectedSector}
                    placeholder={t("txt_sector")}
                    dataKey={DataKey.Sector}
                    displayCode
                    body={body}
                    icon={faTag}
                    controlId="sectorPicker"
                    required={props.required}
                    value={props.value ? [props.value] : []}
                />
            }
        </>
    );
}