import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../Redux/hooks';
import { convertDateNumberToString } from '../Business/utils/DateUtils';
import { PulseLoader } from 'react-spinners';

export interface PropsData {
    date: string;
    time: string;
    supportIdx: number;
    colorClass: string;
    grp: number;
    duration: number;
}

export interface PropsHourData {
    date: string;
    hour: string;
    colorClass: string;
    letter: string;
    data: PropsData[];
}

type Props = {
    hourData: Map<string, PropsHourData>;
    durations: number[];
    setStartDateKPI: (date: string) => void;
    setEndDateKPI: (date: string) => void;
};


const TimeTablePopOver = React.forwardRef((props: any, ref) => {
    const renderTooltipContent = (data: PropsData) => {
        const durationIdx = props.durations.findIndex((duration: number) => duration === data.duration);
        const letter = String.fromCharCode(65 + durationIdx);

        return (
            <>
                <span className={`colored-circle-timetable ${data.colorClass}`}>
                    <span className='colored-circle-timetable-text'>{letter}</span>
                </span>
                <span className="colored-circle-timetable-legend-value">{data.duration}s</span><br />
                <span className={`${data.colorClass}-text tooltipTitle`}>{data.time}</span><br />
                <span>{data.grp.toFixed(2)} GRP</span>
            </>
        );
    };

    return (
        <Tooltip ref={ref} id="tooltip" {...props}>
            <div>
                {props.data.data.map((data: PropsData, idx: number) => (
                    <div key={idx}>
                        {renderTooltipContent(data)}
                    </div>
                ))}
            </div>
        </Tooltip>
    );
})

const Timetable: React.FC<Props> = ({ hourData, durations, setStartDateKPI, setEndDateKPI }) => {
    const { t } = useTranslation();
    const locale = useAppSelector(state => state.locale.locale);
    // Initialize date and time formatters for display
    const dayFormatter = new Intl.DateTimeFormat(locale === "fr" ? 'fr-FR' : "en-US", { weekday: 'short' });
    const dateFormatter = new Intl.DateTimeFormat(locale === "fr" ? 'fr-FR' : "en-US", { day: 'numeric' });
    const monthFormatter = new Intl.DateTimeFormat(locale === "fr" ? 'fr-FR' : "en-US", { month: 'long' });
    const getTimezoneOffset = (): number => new Date().getTimezoneOffset() / 60;
    const timezoneOffset = getTimezoneOffset();

    const [fullDays, setFullDays] = React.useState<boolean>(true);
    const [fullHours, setFullHours] = React.useState<boolean>(true);
    const [days, setDays] = React.useState<Date[]>([]);
    const [startDate, setStartDate] = React.useState<Date>(new Date());
    const [endDate, setEndDate] = React.useState<Date>(new Date());
    const [dateTimeList, setDateTimeList] = React.useState<number[]>([]);
    const [timeSlots, setTimeslots] = React.useState<string[]>([]);

    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        setLoading(true);
        // Get timezone offset and date-time list from data prop
        const tmpDateTimeList = [...hourData.values()].map(({ date, hour }) => new Date(`${date}T${hour}`).getTime());
        tmpDateTimeList.sort((a, b) => a - b);

        setDateTimeList(tmpDateTimeList);

        // Get minimum and maximum date-time from the list
        const minDateTime = new Date(Math.min(...tmpDateTimeList));
        const maxDateTime = new Date(Math.max(...tmpDateTimeList));

        // Get start and end dates from the minimum and maximum date-times
        setStartDate(new Date(minDateTime.getFullYear(), minDateTime.getMonth(), minDateTime.getDate()));
        setEndDate(new Date(maxDateTime.getFullYear(), maxDateTime.getMonth(), maxDateTime.getDate() + 1));

        // Set KPI start and end dates
        setStartDateKPI(convertDateNumberToString(minDateTime.getFullYear(), minDateTime.getMonth() + 1, minDateTime.getDate(), locale));
        setEndDateKPI(convertDateNumberToString(maxDateTime.getFullYear(), maxDateTime.getMonth() + 1, maxDateTime.getDate(), locale));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hourData]);

    // Create an array of days from start to end dates
    React.useEffect(() => {
        const tmpDays = Array.from({ length: Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) }, (_, i) => {
            const date = new Date(startDate.getTime() + i * (1000 * 60 * 60 * 24));
            date.setHours(date.getHours() - timezoneOffset);
            return date;
        });

        if (!fullDays) {
            // Remove days that are not in the date-time list
            const tmpDaysFiltered = tmpDays.filter((day) => {
                const dayStart = new Date(day.getFullYear(), day.getMonth(), day.getDate()).getTime();
                const dayEnd = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1).getTime();
                return dateTimeList.some((dateTime) => dateTime >= dayStart && dateTime < dayEnd);
            });

            setDays(tmpDaysFiltered);
        }
        else
            setDays(tmpDays);

        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate, startDate, dateTimeList, fullDays]);

    React.useEffect(() => {
        // Create an array of time slots for the timetable
        let tmpTimeSlots = Array.from(Array(24).keys()).map((hour) => `${hour.toString().padStart(2, '0')}:00`);

        if (!fullHours) {
            const tmpTimeSlotsFiltered = tmpTimeSlots.filter((timeSlot) => {
                // find in the date-time list if there is a date-time that is between the current time slot and the next one
                // we only check for time, not for date
                return dateTimeList.some((dateTime) => {
                    const dateTimeHour = new Date(dateTime).getHours();
                    const dateTimeMinute = new Date(dateTime).getMinutes();
                    const timeSlotHour = parseInt(timeSlot.split(':')[0]);
                    const timeSlotMinute = parseInt(timeSlot.split(':')[1]);

                    return dateTimeHour === timeSlotHour && dateTimeMinute >= timeSlotMinute;
                });
            });

            setTimeslots(tmpTimeSlotsFiltered);
        }
        else
            setTimeslots(tmpTimeSlots);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullHours])

    const renderCellContent = (day: Date, timeSlot: string): JSX.Element => {
        const foundData = [...hourData.values()].find(({ date, hour }) => {
            const timeSlotStart = (new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0));
            timeSlotStart.setHours(parseInt(timeSlot.split(':')[0]));

            // Compare date and time slot with the current day and time slot
            // If the time in data is 19:30, it has to be between 19:00 and 19:59
            return new Date(`${date}T${hour}`).getTime() >= timeSlotStart.getTime() &&
                new Date(`${date}T${hour}`).getTime() <= timeSlotStart.getTime() + 59 * 60 * 1000
        });

        if (foundData) {
            return (
                <OverlayTrigger placement='bottom' overlay={<TimeTablePopOver durations={durations} data={foundData} />}>
                    <span className={`colored-circle-timetable ${foundData.colorClass}`}>
                        <span className='colored-circle-timetable-text'>{foundData.letter}</span>
                    </span>
                </OverlayTrigger>
            );
        }

        return <span></span>;
    }

    const renderDurationLegend = (duration: number, idx: number) => {
        const upperLetter = String.fromCharCode(65 + idx);
        return (
            <span className="durationLegend" key={idx}><span className={`colored-circle-timetable grey`}>
                <span className='colored-circle-timetable-legend'>{upperLetter}</span>
            </span>
                <span className="colored-circle-timetable-legend-value">{duration}s</span></span>
        );
    }

    return (
        <div>
            <div className="timetable-config-header">
                <Container fluid>
                    <Row className="legend">
                        <Col md={7} style={{ marginLeft: -10 }}>
                            {durations.map((duration, idx) => renderDurationLegend(duration, idx))}
                        </Col>
                        <Col md={2} className="colCheckbox">
                            <Form.Check type="checkbox" id='check-box-complete-days'>
                                <Form.Check.Input type="checkbox" checked={fullDays} onChange={(e) => setFullDays(!fullDays)} />
                                <Form.Check.Label>{t("txt_full_days")}</Form.Check.Label>
                            </Form.Check>
                        </Col>
                        <Col md={3} className="colCheckbox">
                            <Form.Check type="checkbox" id='check-box-complete-hours'>
                                <Form.Check.Input type="checkbox" checked={fullHours} onChange={(e) => setFullHours(!fullHours)} />
                                <Form.Check.Label>{t("txt_full_hours")}</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="timetable-wrapper">
                {loading ?
                    <div className="solutionLoader"><PulseLoader aria-label='Loading' /></div>
                    :
                    <table className="timetable">
                        <thead>
                            <tr className="header-dates">
                                <th></th>
                                {days.map((day, index) => {
                                    const dayOfMonth = parseInt(dateFormatter.format(day));
                                    const isFirstDayOfMonth = dayOfMonth === 1;
                                    const shouldRenderMonthName = isFirstDayOfMonth || (day.getMonth() !== days[index - 1]?.getMonth() && dayOfMonth < 27);

                                    return (
                                        <th key={day.toISOString()}>
                                            {shouldRenderMonthName && (
                                                <div className="month-cell">
                                                    {monthFormatter.format(day).toUpperCase()}, {day.getFullYear()}
                                                </div>
                                            )}

                                            <div className="day-cell">
                                                <span className="dayOfWeek">{dayFormatter.format(day).toUpperCase().slice(0, 1)}</span>
                                                {dayOfMonth}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {timeSlots.map((timeSlot) => (
                                <tr key={timeSlot}>
                                    <td className="time-cell">
                                        <span>{timeSlot.substring(0, timeSlot.length - 3)}</span>
                                    </td>
                                    {days.map((day) => (
                                        <td key={day.toISOString() + timeSlot}>
                                            {renderCellContent(day, timeSlot)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>

        </div>
    );
};

export default Timetable;
