import React from "react";
import { DataRetrieval } from "../../Business/dto/ExternalSystemRetrieveBody";
import { Country } from "../../Business/dto/Brief";
import { ExternalDataPicker } from "./ExternalDataPicker";
import { DataKey } from "../../Business/dto/ExternalSystemElement";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface CountryPickerProps {
    retrieveCountriesUrl: string;
    retrieveCountriesBody: any;
    retrieveCountriesMethod: "get" | "post";
    retrieveCountriesParser: any;
    setSelectedCountry: (country: Country) => void;
    required?: boolean;
    value: Country | undefined;
}

export const CountryPicker = (props: CountryPickerProps) => {
    const { t } = useTranslation();
    const [body, setBody] = React.useState<DataRetrieval | undefined>(undefined);
    
    React.useEffect(() => {
        setBody({
            endpoint: props.retrieveCountriesUrl,
            method: props.retrieveCountriesMethod,
            body: props.retrieveCountriesBody,
            parser: props.retrieveCountriesParser
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {body &&
                <ExternalDataPicker
                    setSelectedData={props.setSelectedCountry}
                    placeholder={t("txt_country_label")}
                    dataKey={DataKey.Country}
                    body={body}
                    icon={faGlobe}
                    required={props.required}
                    controlId="countryPicker"
                    value={props.value ? [props.value] : []}
                />
            }
        </>
    );
}