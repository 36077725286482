import React from "react";
import { DataRetrieval } from "../../Business/dto/ExternalSystemRetrieveBody";
import { Product } from "../../Business/dto/Brief";
import { ExternalDataPicker } from "./ExternalDataPicker";
import { DataKey } from "../../Business/dto/ExternalSystemElement";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { DefaultInput } from "./DefaultInput";

interface ProductPickerProps {
    disabled?: boolean;
    retrieveProductsUrl: string;
    retrieveProductsBody: any;
    retrieveProductsMethod: "get" | "post";
    retrieveProductsParser: any;
    setSelectedProduct: (Product: Product) => void;
    required?: boolean;
    value: Product | undefined;
    allowNew?: boolean;
}

export const ProductPicker = (props: ProductPickerProps) => {
    const { t } = useTranslation();
    const [body, setBody] = React.useState<DataRetrieval | undefined>(undefined);

    React.useEffect(() => {
        setBody({
            endpoint: props.retrieveProductsUrl,
            method: props.retrieveProductsMethod,
            body: props.retrieveProductsBody,
            parser: props.retrieveProductsParser
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.retrieveProductsUrl, props.retrieveProductsBody]);

    return (
        <>
            {!props.allowNew ?
                <>
                    {body &&
                        <ExternalDataPicker
                            allowNew
                            disabled={props.disabled}
                            setSelectedData={props.setSelectedProduct}
                            placeholder={t("product")}
                            dataKey={DataKey.Product}
                            body={body}
                            icon={faBuilding}
                            controlId="productPicker"
                            required={props.required}
                            value={props.value ? [props.value] : []}
                        />
                    }
                </>
            :
                <>
                    <DefaultInput
                        required={props.required}
                        type="text"
                        iconKey="building"
                        placeholder={t("product")}
                        value={props.value ? props.value.name : ""}
                        onChange={(value) => {
                            if (props.setSelectedProduct)
                                props.setSelectedProduct({ id: "-1", name: value});
                        }}
                    />
                </>
            }
        </>
    );
}