export interface ExternalSystemElement {
    id: string;
    name: string;
}

export enum DataKey {
    Sector = "sector",
    Target = "target",
    Media = "media",
    MediaChannel = "mediaChannel",
    DayPart = "daypart",
    Country = "country",
    Currency = "currency",
    Agency = "agency",
    Advertiser = "advertiser",
    Product = "product",
    Competition = "mediaChannelCompetitionCodes"
}