import React from 'react';
import { Colors, DurationData, MediaChannelData, SolutionView } from './AllocatorSolutions';
import Timetable, { PropsHourData } from './AllocatorSolutionTimetable';
import { AllocatedScreen } from '../Business/dto/AllocationSolution';

interface AllocatorSolutionCalendarProps {
    solution: any;
    solutionView: SolutionView;
    mediaChannelData: MediaChannelData[];
    durationData: DurationData[];
    selectedElementIdx: number;
    setStartDateKPI: (date: string) => void;
    setEndDateKPI: (date: string) => void;
}

export const AllocatorSolutionCalendar = (props: AllocatorSolutionCalendarProps) => {
    // key is datehour, e.g. 2021-01-0100 or 2021-01-0101
    const [hourData, setHourData] = React.useState<Map<string, PropsHourData>>(new Map<string, PropsHourData>());

    React.useEffect(() => {
        if (props.solution && props.mediaChannelData.length > 0) {
            const durations = props.durationData.map(d => d.duration);
            const tmpHourData: Map<string, PropsHourData> = new Map<string, PropsHourData>();
            const screensToProcess = [];

            if (props.solutionView === SolutionView.Global) {
                props.mediaChannelData.forEach((entry: MediaChannelData) => {
                    screensToProcess.push(...entry.screens);
                });
            }
            else if (props.solutionView === SolutionView.BySupport) {
                if (props.selectedElementIdx >= props.mediaChannelData.length)
                    screensToProcess.push(...props.mediaChannelData[0].screens);
                else
                    screensToProcess.push(...props.mediaChannelData[props.selectedElementIdx].screens);
            }
            else if (props.solutionView === SolutionView.BySupportByDayPart) {
                let idx = 0;

                props.mediaChannelData.forEach(data => {
                    data.availableDayParts.forEach(dayPart => {
                        if (idx === props.selectedElementIdx) {
                            screensToProcess.push(...dayPart.screens);
                        }
                        idx++;
                    });
                });
            }
            else if (props.solutionView === SolutionView.ByFormatByPeriod) {
                let idx = 0;

                props.durationData.forEach(data => {
                    data.periods.forEach(period => {
                        if (idx === props.selectedElementIdx) {
                            screensToProcess.push(...period.screens);
                        }
                        idx++;
                    });
                });
            }
            else if (props.solutionView === SolutionView.ByFormat) {
                if (props.selectedElementIdx >= props.durationData.length)
                    screensToProcess.push(...props.durationData[0].screens);
                else
                    screensToProcess.push(...props.durationData[props.selectedElementIdx].screens);
            }

            screensToProcess.forEach((screen: AllocatedScreen) => {
                let startTime = screen.time.toString();
                const screenDate = new Date(screen.date / 10000, screen.date % 10000 / 100 - 1, screen.date % 100, 2);

                if (screen.time > 240000) {
                    screenDate.setDate(screenDate.getDate() + 1);
                    startTime = (screen.time - 240000).toString();
                }

                while (startTime.length < 6)
                    startTime = '0' + startTime;

                startTime = startTime.slice(0, 2) + ':' + startTime.slice(2, 4);
                let screenDateString = `${screenDate.getUTCFullYear()}`;

                if (screenDate.getUTCMonth() + 1 < 10)
                    screenDateString += `-0${screenDate.getUTCMonth() + 1}`;
                else
                    screenDateString += `-${screenDate.getUTCMonth() + 1}`;

                if (screenDate.getUTCDate() < 10)
                    screenDateString += `-0${screenDate.getUTCDate()}`;
                else
                    screenDateString += `-${screenDate.getUTCDate()}`;

                const channels = props.mediaChannelData.map((entry: MediaChannelData) => entry.channel);
                const tmpData = {
                    date: screenDateString,
                    time: startTime,
                    colorClass: Colors[channels.indexOf(screen.channel)],
                    supportIdx: channels.indexOf(screen.channel),
                    grp: screen.audience,
                    duration: screen.duration
                }

                const key = `${screenDateString}${Number.parseInt(startTime.slice(0, 2))}`;

                if (!tmpHourData.has(key)) {
                    const tmpHourDataEntry: PropsHourData = {
                        date: screenDateString,
                        hour: startTime.slice(0, 3) + '00',
                        colorClass: tmpData.colorClass,
                        data: [tmpData],
                        letter: String.fromCharCode(65 + durations.indexOf(screen.duration))
                    };

                    tmpHourData.set(key, tmpHourDataEntry);
                }
                else {
                    const tmpHourDataEntry = tmpHourData.get(key);

                    if (tmpHourDataEntry) {
                        tmpHourDataEntry.data.push(tmpData);

                        tmpHourDataEntry.colorClass = "black";

                        // If multiple durations, set letter to ...
                        const dataDurations = new Set(tmpHourDataEntry.data.map(d => d.duration));

                        if (dataDurations.size > 1)
                            tmpHourDataEntry.letter = "...";
                        else
                            tmpHourDataEntry.letter = String.fromCharCode(65 + durations.indexOf(screen.duration));


                        tmpHourData.set(key, tmpHourDataEntry);
                    }
                }

            });
            setHourData(tmpHourData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedElementIdx, props.solutionView, props.solution, props.mediaChannelData])

    return (
        <div className="solutionPresentationContent solutionTimeTable">
            {[...hourData.keys()].length > 0 &&
                <Timetable hourData={hourData} durations={props.durationData.map(d => d.duration)} setStartDateKPI={props.setStartDateKPI} setEndDateKPI={props.setEndDateKPI} />
            }
        </div>
    );
}

export default AllocatorSolutionCalendar;