import axios from "axios";

export type ApiClientKey = string;

export class ApiClient {
    protected baseUrl = process.env.REACT_APP_SERVER_URL;
    public static key = "ApiClient";

    protected async Post<T>(url: string, body: any, accessToken?: string): Promise<T> {
        return (await axios.post(url, body, accessToken ? { headers: { "x-access-token": accessToken }} : {} )).data;
    }

    protected async Get<T>(url: string, accessToken?: string): Promise<T> {
        return (await axios.get(url, accessToken ? { headers: { "x-access-token": accessToken }} : {} )).data;
    }

    protected async Put<T>(url: string, body: any, accessToken?: string): Promise<T> {
        return (await axios.put(url, body, accessToken ? { headers: { "x-access-token": accessToken }} : {} )).data;
    }
}