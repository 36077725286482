import { faMoneyBill1 } from "@fortawesome/free-regular-svg-icons";
import { ChoicePicker, ChoicePickerOption } from "./ChoicePicker";
import { useTranslation } from "react-i18next";


interface SellingStrategyPickerProps {
    setSelectedSellingStrategy: (data: any) => void;
    required?: boolean;
    value: SellingStrategyOption | undefined;
}

export interface SellingStrategyOption extends ChoicePickerOption {
    id: "gross" | "discount" | "costgrp"
    name: string
}

export const SellingStrategyPicker = (props: SellingStrategyPickerProps) => {
    const { t } = useTranslation();
    const data: ChoicePickerOption[] = [
        {id: "discount", name: t("txt_method_discount")},
        {id: "costgrp", name: t("txt_method_grpc")}
    ]

    return (
        <ChoicePicker
            setSelectedData={props.setSelectedSellingStrategy}
            placeholder={t("txt_selling_strategy")}
            data={data}
            multiple={false}
            icon={faMoneyBill1}
            required={props.required}
            controlId="sellingStrategyPicker"
            value={props.value ? [props.value] : []}
        />
);
}