import { Container, Form } from "react-bootstrap"
import { DataTableCellPicker } from "./DataTableCellPicker"
import { DataTableV2DataRowCell } from "./DataTableV2"
import { PeriodPicker } from "./PeriodPicker"

export interface DataTableRowCellContentProps {
    cell: DataTableV2DataRowCell,
    onChange: any
    onBlur?: any
}


export const DataTableRowCellContent = (props: DataTableRowCellContentProps) => {

    const formatPercent = (value : string) : string => {
        const maxSize : number = 5;
        return value.length > maxSize ? value.slice(0, maxSize) : value
    }


    if (!props.cell)
        return <></>

    if (!props.cell.editable && props.cell.type === "percentage")
        return <span>{formatPercent(props.cell.value)}</span>
        
    if (!props.cell.editable)
        return <span>{props.cell.value}</span>

    if (props.cell.type === "picker" && props.cell.pickerOptions)
        return <DataTableCellPicker value={props.cell.value} pickerOptions={props.cell.pickerOptions} onChange={props.onChange} />

    if (props.cell.type === "date" && props.cell.setStartDate && props.cell.setEndDate)
        return (
            <Container className="periodPickerContainer">
                <PeriodPicker maxDate={props.cell.maxDate} startDate={props.cell.startDate} endDate={props.cell.endDate} setStartDate={props.cell.setStartDate} setEndDate={props.cell.setEndDate} monoCol/>
            </Container>
        )

    if (props.cell.type === "percentage")
        return <Form.Control
        className={props.cell.type === "percentage" ? "percentageInput" : ""}
        placeholder="%"
        type={props.cell.type}
        style={{ textAlign: "center" }}
        size="sm"
        min={0}
        max={100}
        value={formatPercent(props.cell.value)}
        onChange={props.onChange}
        onBlur={props.onBlur}
        />

    return <Form.Control
        placeholder="..."
        type={props.cell.type}
        style={{ textAlign: "center" }}
        size="sm"
        value={props.cell.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
    />
}