import React from "react";
import { Tab } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { SearchBriefs } from "../Redux/Reducers/briefSlice";
import { useParams } from "react-router-dom";
import { Advertiser, BriefDTO, Product } from "../Business/dto/Brief";
import { t } from "i18next";
import "./AllocationHistory.css";
import { BasicTable } from "../Components/BasicTable";
import { TableColumn } from "react-data-table-component";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import { numberToDateObject } from "../Business/utils/DateUtils";
import { formatNumberToCurrency } from "../Business/utils/CurrencyUtils";
import { GetBriefStatus } from "../Business/utils/BriefUtils";
import "./AllocationHistory.css";
import { ClientIdLink } from "../Router";

export const AllocationHistory = () => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector(state => state.locale.locale);
    moment.locale(locale);
    const { clientId } = useParams();
    const history = useAppSelector(state => state.brief.history);
    const [columns, setColumns] = React.useState<TableColumn<BriefDTO>[]>([]);
    
    React.useEffect(() => {
        setColumns([
            {
                name: t("txt_date_demande"),
                selector: row => moment((row as any).createdAt).format("L LT"),
                sortable: true,
                sortFunction: (a, b) => moment((a as any).createdAt).unix() - moment((b as any).createdAt).unix(),
                wrap: false,
                allowOverflow: true
            },
            {
                name: t("txt_start_date"),
                selector: row => moment(numberToDateObject(row.brief.StartDate)).format("L"),
                sortable: true,
                sortFunction: (a, b) => moment(numberToDateObject(a.brief.StartDate)).unix() - moment(numberToDateObject(b.brief.StartDate)).unix()
            },
            {
                name: t("txt_end_date"),
                selector: row => moment(numberToDateObject(row.brief.EndDate)).format("L"),
                sortable: true,
                sortFunction: (a, b) => moment(numberToDateObject(a.brief.EndDate)).unix() - moment(numberToDateObject(b.brief.EndDate)).unix()
            },
            {
                name: t("annoncer"),
                selector: row => (row.brief.Advertiser as Advertiser).name,
                sortable: true
            },
            {
                name: t("product"),
                selector: row => (row.brief.Product as Product).name,
                sortable: true
            },
            {
                name: t("budget"),
                selector: row => formatNumberToCurrency(row.brief.Budget, row.brief.Currency),
                sortable: true
            },
            {
                name: t("txt_status"),
                selector: row => t(GetBriefStatus(row).text),
                cell: (row, _rowIdx, _column, _id) => {
                    const status = GetBriefStatus(row);

                    return <div className={`allocationStatusBadge-${status.color}`}>{t(status.text)}</div>
                },
                sortable: true,
                sortFunction: (a, b) => GetBriefStatus(a).text.localeCompare(GetBriefStatus(b).text)
            },
            {
                name: t("txt_details"),
                cell: (row, _rowIdx, _column, _id) => {
                    if (row.allocatorSessionId === undefined || row.allocatorSessionRunId === undefined || row.allocatedMediaPlans === undefined || row.allocatedMediaPlans === 0)
                        return <></>

                    return (
                        <ClientIdLink to={`/allocatorSolutions/${row.allocatorSessionRunId}`} props={{ state: { briefId: (row as any)._id } }}>
                            {t("txt_details")}
                        </ClientIdLink>
                    );
                }
            }
        ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        dispatch(SearchBriefs({ clientId }));
    }, [dispatch, clientId]);

    return (
        <>

            <Tab.Container id="allocation-list-group-tabs">
                <BasicTable<BriefDTO>
                    data={history}
                    columns={columns}
                    pagination={{ itemsPerPage: 10 }}
                    sortOptions={{ defaultSortColumnIdx: 1, defaultSortDirection: "desc" }}
                    keyField="_id"
                />
            </Tab.Container>
        </>
    );
};