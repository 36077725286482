import React, { Suspense } from 'react';
import './App.css';
import { useAppDispatch, useAppSelector } from './Redux/hooks';
import { JamRouter } from './Router';
import 'bootstrap/dist/css/bootstrap.css';
import JamContext from './Components/Misc/JamContext';
import { setLocale } from './Redux/Reducers/localeSlice';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import CacheBuster from 'react-cache-buster';
const pjson = require('../package.json');

function App() {
	const dispatch = useAppDispatch()
	const locale = useAppSelector(state => state.locale.locale);
	const clientConfig = useAppSelector(state => state.clientConfig.config);
	const isProduction = process.env.NODE_ENV === 'production';

	React.useEffect(() => {
		if (clientConfig) {
			document.documentElement.style.setProperty('--primary-color', clientConfig.primaryColor);
			document.documentElement.style.setProperty('--secondary-color', clientConfig.secondaryColor);
			document.documentElement.style.setProperty('--tertiary-color', clientConfig.tertiaryColor);
			console.log("version", pjson.version)
		}
	}, [clientConfig]);

	const handleChange = (event: any) => {
		dispatch(setLocale(event.target.value));
		
	}

	const contextValue = {
		handleChange: handleChange,
		language: locale
	}

	return (
		<CacheBuster
			isEnabled={isProduction}
			currentVersion={pjson.version}
			isVerboseMode={false}
		>
			<div>
				<Suspense fallback={<div>Loading...</div>}>
					<JamContext.Provider value={contextValue}>
						<ToastContainer stacked={true} />
						<JamRouter />
					</JamContext.Provider>
				</Suspense>
			</div>
		</CacheBuster>
	);
}

export default App;
