import { Col, Row } from "react-bootstrap"
import './DaysPicker.css'
import { useTranslation } from "react-i18next";

interface DaysPickerProps {
    selectedDays: number[];
    setSelectedDays: (days: number[]) => void;
}

export const DaysPicker = (props: DaysPickerProps) => {
    const { t } = useTranslation();
    const days = [
        { name: t("mon"), value: 1 },
        { name: t("tue"), value: 2 },
        { name: t("wed"), value: 3 },
        { name: t("thu"), value: 4 },
        { name: t("fri"), value: 5 },
        { name: t("sat"), value: 6 },
        { name: t("sun"), value: 0 }
    ];

    const onDayClick = (day: number) => {
        if (props.selectedDays.includes(day)) {
            props.setSelectedDays(props.selectedDays.filter(x => x !== day));
        } else {
            props.setSelectedDays([...props.selectedDays, day]);
        }
    }

    const mapDays = (day: { name: string, value: number }) => {
        return (
            <Col key={day.value} onClick={() => onDayClick(day.value)}>
                <p className={`dayCol ${props.selectedDays.includes(day.value) ? 'selected' : ''}`}>{day.name.substring(0, 3)}</p>
            </Col>
        );
    }

    return (
        <Row>
            {days.map(mapDays)}
        </Row>
    )
}