import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { Input } from "../Components/Inputs/Input"
import { DefaultInput } from "../Components/Inputs/DefaultInput"
import { useTranslation } from 'react-i18next';
import React from "react";
import { AuthApiClient } from "../Business/api/AuthApiClient";
import { ApiClientManager } from "../Business/api/ApiClientManager";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const ResetPassword = () => {
    const { t } = useTranslation();
    const { token } = useParams();
    const { clientId } = useParams();
    const [displayError, setDisplayError] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);

    const onFormSubmit = async (event: any) => {
        event.preventDefault();
        const mdp = event.target[0].value;
        const confirmMdp = event.target[1].value;
        if (mdp !== confirmMdp) {
            setDisplayError(true);
        }
        else {
            try {
                setDisplayError(false);
                await ApiClientManager.getInstance().getApiClient<AuthApiClient>(AuthApiClient.key).ResetPassword(mdp, token || "");
                setIsSuccess(true);
                setTimeout(() => {
                    window.location.href = `/${clientId}/`;
                }, 5000);
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    React.useEffect(() => {
        if(isSuccess) {
            toast.success(t('txt_reset_pass_success'));
        }
    },[isSuccess])

    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="leftSection">
                        <Row className="mb-3">
                            <Col>
                                <img className="homeLogo" src="/img/JAM_ Logo&Slogan.svg" alt="JAM LOGO" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <h1 className="homeTitle">{t("txt_change_pass")}</h1>
                            </Col>
                        </Row>
                        <Form onSubmit={onFormSubmit}>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group>
                                        <Input title={t("txt_pass_label")}>
                                            <DefaultInput type="password" iconKey='password' placeholder={t('txt_pass_label')} onChange={(_value) => { }} />
                                        </Input>
                                        <Input title={t("txt_confirm_pass")}>
                                            <DefaultInput type="password" iconKey='password' placeholder={t('txt_confirm_pass')} onChange={(_value) => { }} />
                                        </Input>
                                    </Form.Group>
                                </Col>
                                {displayError &&
                                    <Row className="errorTextRow">
                                        <Col>
                                            {t("txt_pass_match_err")}
                                        </Col>
                                    </Row>}
                            </Row>
                            <Row className="mb-3 ">
                                <Col>
                                    <Button className="loginButton" type="submit">{t("txt_send")}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col className="d-none d-md-block rightSection">
                        <img className="imgRightSection" src="/img/RightSection.svg" alt="Right Section" />
                    </Col>
                </Row>
            </Container >
        </>
    )
}