import React from "react";
import { SolutionView, Colors, MediaChannelData, DurationData } from "./AllocatorSolutions";
import { Form, Container, Row, Col, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faWallet, faCoins, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { AllocationSolution } from "../Business/dto/AllocationSolution";
import { convertDateNumberToString } from "../Business/utils/DateUtils";
import { useAppSelector } from "../Redux/hooks";
import { BriefDTO } from "../Business/dto/Brief";
import { getCurrencySymbol } from "../Business/utils/CurrencyUtils";

interface AllocatorSolutionKPIsProps {
    solutionNumber: number;
    solution: AllocationSolution;
    solutionView: SolutionView;
    showDayPartView: boolean;
    showFormatPeriodView: boolean;
    showFormatView: boolean;
    mediaChannelData: MediaChannelData[];
    durationData: DurationData[];
    setSolutionView: (view: SolutionView) => void;
    selectedElementIdx: number;
    setSelectedElementIdx: (idx: number) => void;
    startDate: string;
    endDate: string;
    brief: BriefDTO;
}

export const AllocatorSolutionKPIs = (props: AllocatorSolutionKPIsProps) => {
    const { t } = useTranslation();
    const [budget, setBudget] = React.useState<number>(0);
    const [nbSpots, setNbSpots] = React.useState<number>(0);
    const locale = useAppSelector(state => state.locale.locale);
    const [budgetToAllocate, setBudgetToAllocate] = React.useState<number>(0);
    const [allocatedBudgetPercent, setAllocatedBudgetPercent] = React.useState<string>("0");
    const [grp, setGrp] = React.useState<string>("0");
    const [grpRepartition, setGrpRepartition] = React.useState<string>("0");
    const [allocatedBudgetSupport, setAllocatedBudgetSupport] = React.useState<string>("0");
    const [dayPartMediaChannelBudget, setDayPartMediaChannelBudget] = React.useState<number>(0);

    React.useEffect(() => {
        if (props.solution && props.solutionView) {
            if (props.mediaChannelData.length > 0 && props.durationData.length > 0) {
                if (props.solutionView === SolutionView.BySupport) {
                    if (props.selectedElementIdx >= props.mediaChannelData.length) {
                        props.setSelectedElementIdx(0);
                    }
                    else {
                        setBudget(props.mediaChannelData[props.selectedElementIdx].budget);
                        setGrp(props.mediaChannelData[props.selectedElementIdx].grp.toFixed(2));
                        setNbSpots(props.mediaChannelData[props.selectedElementIdx].screens.length);
                    }
                }
                else if (props.solutionView === SolutionView.BySupportByDayPart) {
                    let idx = 0;

                    props.mediaChannelData.forEach(data => {
                        data.availableDayParts.forEach(dayPart => {
                            if (idx === props.selectedElementIdx) {
                                const percent = dayPart.budget / (props.mediaChannelData.find(channel => channel.channel === data.channel)?.targetBudget || 1) * 100;
                                setBudget(dayPart.budget);
                                setGrp(dayPart.screens.reduce((acc, screen) => acc + screen.audience, 0).toFixed(2));
                                setNbSpots(dayPart.screens.length);
                                setAllocatedBudgetSupport(percent.toFixed(2));
                                setDayPartMediaChannelBudget(data.budget);
                            }
                            idx++;
                        });
                    });
                }
                else if (props.solutionView === SolutionView.ByFormatByPeriod) {
                    let idx = 0;

                    props.durationData.forEach(data => {
                        data.periods.forEach(period => {
                            if (idx === props.selectedElementIdx) {
                                setBudget(0);
                                setGrp(period.screens.reduce((acc, screen) => acc + screen.audience, 0).toFixed(2));
                                setGrpRepartition((period.grpRepartition * 100).toFixed(2));
                                setNbSpots(period.screens.length);
                            }
                            idx++;
                        });
                    });
                }
                else if (props.solutionView === SolutionView.ByFormat) {
                    setBudget(0);
                    setGrp(props.durationData[props.selectedElementIdx].screens.reduce((acc, screen) => acc + screen.audience, 0).toFixed(2));
                    setNbSpots(props.durationData[props.selectedElementIdx].screens.length);
                    setGrpRepartition((props.durationData[props.selectedElementIdx].grpRepartition * 100).toFixed(2));
                }
                else if (props.solutionView === SolutionView.Global) {
                    setBudget(props.mediaChannelData.reduce((acc, data) => acc + data.budget, 0));
                    setGrp(props.mediaChannelData.reduce((acc, data) => acc + data.grp, 0).toFixed(2));
                    setNbSpots(props.mediaChannelData.reduce((acc, data) => acc + data.screens.length, 0));
                }
            }

            // Sum target budgets
            setBudgetToAllocate(props.mediaChannelData.reduce((acc, data) => acc + data.targetBudget, 0));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.solution, props.solutionView, props.selectedElementIdx, props.mediaChannelData, props.durationData]);

    React.useEffect(() => {
        if (budgetToAllocate > 0 && budget > 0)
            setAllocatedBudgetPercent((budget / budgetToAllocate * 100).toFixed(2));
        else
            setAllocatedBudgetPercent("0");
    }, [budgetToAllocate, budget]);

    const onViewPageItemClick = (event: any) => {
        const views = [SolutionView.ByFormatByPeriod, SolutionView.ByFormat, SolutionView.BySupportByDayPart, SolutionView.BySupport, SolutionView.Global];
        if (event.target.tabIndex) {
            props.setSolutionView(views[event.target.tabIndex]);
        }
        else
            props.setSolutionView(SolutionView.ByFormatByPeriod);
    }

    const renderSelectionElement = () => {
        if (props.solutionView === SolutionView.Global)
            return props.mediaChannelData.map(renderSupportsGlobal);

        const radiosToRender: string[] = [];

        if (props.solutionView === SolutionView.BySupport)
            radiosToRender.push(...props.mediaChannelData.map(data => data.channel));
        else if (props.solutionView === SolutionView.BySupportByDayPart) {
            props.mediaChannelData.forEach(data => {
                data.availableDayParts.forEach(dayPart => {
                    radiosToRender.push(data.channel + " - " + dayPart.dayPartLabel);
                })
            });
        }
        else if (props.solutionView === SolutionView.ByFormatByPeriod) {
            props.durationData.forEach(data => {
                data.periods.forEach(period => {
                    const dates = period.period.split("~");
                    const startNumber = parseInt(dates[0]);
                    const endNumber = parseInt(dates[1]);
                    const year = Math.floor(startNumber / 10000);
                    const month = Math.floor((startNumber / 100) % 100);
                    const day = startNumber % 100;
                    const endYear = Math.floor(endNumber / 10000);
                    const endMonth = Math.floor((endNumber / 100) % 100);
                    const endDay = endNumber % 100;

                    radiosToRender.push(data.duration + " - " + convertDateNumberToString(year, month, day, locale) + " ~ " + convertDateNumberToString(endYear, endMonth, endDay, locale));
                });
            })
        }
        else if (props.solutionView === SolutionView.ByFormat) {
            props.durationData.forEach(data => {
                radiosToRender.push(data.duration.toString());
            });
        }

        return radiosToRender.map(renderRadios);
    }

    const renderRadios = (label: string, index: number) => {
        return (
            <div key={"supportRadio" + index}>
                <Form.Check
                    type='radio'
                    checked={props.selectedElementIdx === index}
                    className="supportRadio"
                    key={"supportRadio" + index}
                    label={label}
                    id={index.toString()}
                    name='supportRadio'
                    style={{ borderColor: props.selectedElementIdx === index ? Colors[index] : '', boxShadow: 'none' }}
                    onChange={(event) => props.setSelectedElementIdx(Number.parseInt(event.target.id))}
                />
            </div>
        )
    };

    const renderSupportsGlobal = (data: MediaChannelData, index: number) => {
        return (
            <div className="supportListItem" key={"support" + index}>
                <span className={"colored-circle " + Colors[index]} /><span className="supportBadgeLabel">{data.channel}</span>
            </div>
        );
    }

    const renderBudgetText = () => {
        switch (props.solutionView) {
            case SolutionView.BySupport:
                return t("txt_media_budget");
            case SolutionView.BySupportByDayPart:
                return t("txt_daypart_budget");
            case SolutionView.ByFormatByPeriod:
                return t("txt_period_budget");
            case SolutionView.ByFormat:
                return t("txt_duration_budget");
            default:
                return t("total_budget");
        }
    }

    return (
        <>
            <div className="solutionPresentationContent">
                {props.solution &&
                    <>
                        <Container fluid>
                            <Row>
                                <Col md={12}>
                                    <h4 className="solutionTitle">{t("txt_proposal")} {props.solutionNumber + 1}</h4>
                                </Col>
                            </Row>
                            <Row className="solutionKPIs">
                                <Col md={2}>
                                    <FontAwesomeIcon className="briefIcon" icon={faCalendar} />
                                </Col>
                                <Col md={10} className="solutionKPICol">
                                    <Row>
                                        <Col md={12}>
                                            {t("package_start_date")}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} style={{ fontWeight: 'bold' }}>
                                            {props.startDate}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {t("package_end_date")}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} style={{ fontWeight: 'bold' }}>
                                            {props.endDate}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {props.solutionView !== SolutionView.ByFormatByPeriod && props.solutionView !== SolutionView.ByFormat && <>
                                <Row className="solutionKPIs">
                                    <Col md={2}>
                                        <FontAwesomeIcon className="briefIcon" icon={faWallet} />
                                    </Col>
                                    <Col md={10} className="solutionKPICol">
                                        <Row>
                                            <Col md={12}>
                                                {renderBudgetText()}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} style={{ fontWeight: 'bold' }}>
                                                {budget.toFixed(2)} {getCurrencySymbol(props.brief.brief.Currency)} {t("txt_net")}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} style={{ fontWeight: 'bold' }}>
                                                {nbSpots} spots
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="solutionKPIs">
                                    <Col md={2}>
                                        <FontAwesomeIcon className="briefIcon" icon={faCoins} />
                                    </Col>
                                    <Col md={10} className="solutionKPICol">
                                        <Row>
                                            <Col md={12}>
                                                {t("txt_allocated_budget")}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} style={{ fontWeight: 'bold' }}>
                                                {allocatedBudgetPercent}{t("txt_percent_of")} {budgetToAllocate.toFixed(0)} {getCurrencySymbol(props.brief.brief.Currency)}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row></>
                            }
                        {props.solutionView === SolutionView.BySupportByDayPart &&
                            <Row className="solutionKPIs">
                                <Col md={2}>
                                    <FontAwesomeIcon className="briefIcon" icon={faCoins} />
                                </Col>
                                <Col md={10} className="solutionKPICol">
                                    <Row>
                                        <Col md={12}>
                                            {t("txt_allocated_budget_channel")}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} style={{ fontWeight: 'bold' }}>
                                            {allocatedBudgetSupport}{t("txt_percent_of")} {dayPartMediaChannelBudget}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>}
                            <Row className="solutionKPIs">
                                <Col md={2}>
                                    <FontAwesomeIcon className="briefIcon" icon={faChartLine} />
                                </Col>
                                <Col md={10} className="solutionKPICol">
                                    <Row>
                                        <Col md={12}>
                                            Gross Rating Point (GRP)
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} style={{ fontWeight: 'bold' }}>
                                            {grp}
                                        </Col>
                                    </Row>
                                    {(props.solutionView === SolutionView.ByFormatByPeriod || props.solutionView === SolutionView.ByFormat) &&
                                        <Row>
                                            <Col md={12} style={{ fontWeight: 'bold' }}>
                                                {nbSpots} spots
                                            </Col>
                                        </Row>}
                                </Col>
                            </Row>
                            {(props.solutionView === SolutionView.ByFormatByPeriod || props.solutionView === SolutionView.ByFormat) &&
                                <Row className="solutionKPIs">
                                    <Col md={2}>
                                        <FontAwesomeIcon className="briefIcon" icon={faChartLine} />
                                    </Col>
                                    <Col md={10} className="solutionKPICol">
                                        <Row>
                                            <Col md={12}>
                                                {t("txt_grp_repartition_percent")}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} style={{ fontWeight: 'bold' }}>
                                                {grpRepartition}%
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </Container>
                    </>
                }
            </div>
            <Container fluid>
                <Row className="vueGrille">
                    <Col md={12}>
                        <h4 className="solutionSubTitle">{t("txt_vue_grille")}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="kpiPaginationCol" md={12}>
                        <Pagination className="kpiPaginationRoot" size="sm">
                            {props.showFormatPeriodView &&
                            <Pagination.Item
                                className="kpiPaginationItem"
                                active={props.solutionView === SolutionView.ByFormatByPeriod}
                                onClick={onViewPageItemClick}
                                tabIndex={0}>
                                {t("txt_by_period")}
                            </Pagination.Item>}
                            {props.showFormatView &&
                            <Pagination.Item
                                className="kpiPaginationItem"
                                active={props.solutionView === SolutionView.ByFormat}
                                onClick={onViewPageItemClick}
                                tabIndex={1}>
                                {t("txt_by_duration")}
                            </Pagination.Item>}
                            {props.showDayPartView &&
                            <Pagination.Item
                                className="kpiPaginationItem"
                                active={props.solutionView === SolutionView.BySupportByDayPart}
                                onClick={onViewPageItemClick}
                                tabIndex={2}>
                                {t("txt_by_daypart")}
                            </Pagination.Item>}
                            <Pagination.Item
                                className="kpiPaginationItem"
                                active={props.solutionView === SolutionView.BySupport}
                                onClick={onViewPageItemClick}
                                tabIndex={3}>
                                {t("txt_by_media")}
                            </Pagination.Item>
                            <Pagination.Item
                                className="kpiPaginationItem"
                                active={props.solutionView === SolutionView.Global}
                                onClick={onViewPageItemClick}
                                tabIndex={4}>
                                {t("txt_global")}
                            </Pagination.Item>
                        </Pagination>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div>
                            {renderSelectionElement()}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}