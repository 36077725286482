import { AllocationApiClient } from "./AllocationApiClient";
import { ApiClient } from "./ApiClient";
import { AuthApiClient } from "./AuthApiClient";
import { BriefApiClient } from "./BriefApiClient";
import { ClientApiClient } from "./ClientApiClient";
import { ExternalSystemElementApiClient } from "./ExternalSystemElementApiClient";
import { UserApiClient } from "./UserApiClient";

export class ApiClientManager {
    private static instance: ApiClientManager;
    private apiClients: Map<string, ApiClient> = new Map<string, ApiClient>();

    public static getInstance(): ApiClientManager {
        if (!ApiClientManager.instance) {
            ApiClientManager.instance = new ApiClientManager();
        }

        return ApiClientManager.instance;
    }

    private constructor() {
        this.init();
    }

    private init() {
        this.registerApiClient(new ExternalSystemElementApiClient(), ExternalSystemElementApiClient.key);
        this.registerApiClient(new AuthApiClient(), AuthApiClient.key);
        this.registerApiClient(new UserApiClient(), UserApiClient.key);
        this.registerApiClient(new ClientApiClient(), ClientApiClient.key);
        this.registerApiClient(new BriefApiClient(), BriefApiClient.key);
        this.registerApiClient(new AllocationApiClient(), AllocationApiClient.key);
    }

    private registerApiClient(apiClient: ApiClient, key: string) {
        this.apiClients.set(key, apiClient);
    }

    public getApiClient<T extends ApiClient>(key: string): T {
        return this.apiClients.get(key) as T;
    }
}