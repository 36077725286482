import { Card } from "react-bootstrap";
import { useAppSelector } from "../../Redux/hooks";

interface SectionHeaderProps {
    idx: number;
    children: any;
    onChangeSection: (sectionIdx: number) => void;
}

export const SectionHeader = (props: SectionHeaderProps) => {
    const clientConfig = useAppSelector(state => state.clientConfig.config);
    
    return (
        <Card.Header onClick={() => props.onChangeSection(props.idx)} style={{ backgroundColor: clientConfig?.primaryColor, color: 'white' }}>
            {props.children}
        </Card.Header>
    );
}