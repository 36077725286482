import Stepper from "@keyvaluesystems/react-stepper";
import React from "react";
import { useAppSelector } from "../../Redux/hooks";
import { useTranslation } from "react-i18next";


interface SectionTimeLineProps {
    sectionNames: string[];
    activeStep: number;
    onChangeSection: (step: number) => void;
}

export const SectionTimeLine = (props: SectionTimeLineProps) => {
    const { t } = useTranslation();
    const [steps, setSteps] = React.useState<any>([]);
    const clientConfig = useAppSelector(state => state.clientConfig.config);

    const styles = {
        ActiveLabelTitle: () => ({
            color: clientConfig?.primaryColor,
        }),
        ActiveNode: () => ({
            backgroundColor: clientConfig?.primaryColor,
        }),
        InactiveLineSeparator: () => ({
            backgroundColor: "#E1E1E1",
        })
    }

    React.useEffect(() => {
        if (props) {
            const tmpSteps: any[] = [];

            props.sectionNames.forEach((sectionName) => {
                tmpSteps.push({ stepLabel: t(sectionName) });
            });

            setSteps(tmpSteps);
        }
    }, [props, t])

    return (
        <Stepper styles={styles} steps={steps} currentStepIndex={props.activeStep} />
    );
}