import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ClientIdRedirect } from "../Router";
import { t } from "i18next";
import { useAppDispatch } from "../Redux/hooks";
import { loadToken } from "../Redux/Reducers/authSlice";

export const RedirectPage = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const token = searchParams.get("token");
    const to = searchParams.get("to");

    React.useEffect(() => {
        if (token) {
            dispatch(loadToken(token))
        }
    }, [dispatch, token]);
    
    return (
        <>
            {(token && to) ?
               <ClientIdRedirect path={to.startsWith('/') ? to : "/" + to} options={{ state: { from: location} }} />
            :
                <h1>{t("txt_redirection_error")}</h1>
            }
        </>
    );
}