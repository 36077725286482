import { Section } from "./Section";
import { GeneralSection } from "./GeneralSection";
import { FormatSection } from "./FormatSection";
import { MediaChannelSection } from "./MediaChannelSection";

/**
 * Singleton class to build sections based on client configuration
 */
export class SectionBuilder {
    private sections: Map<string, Section<any>> = new Map<string, Section<any>>();
    private static instance: SectionBuilder;

    public static getInstance(): SectionBuilder {
        if (!SectionBuilder.instance) {
            SectionBuilder.instance = new SectionBuilder();
        }

        return SectionBuilder.instance;
    }

    private constructor() { 
        this.InitSections();
    }

    private InitSections() {
        this.sections.set("general", new GeneralSection());
        this.sections.set("format", new FormatSection());
        this.sections.set("mediaChannel", new MediaChannelSection());
    }

    public getSection(key: string): Section<any> | undefined {
        return this.sections.get(key);
    }
}