import { ApiResponse } from "../dto/ApiResponse";
import { BriefDTO } from "../dto/Brief";
import { resolveUrl } from "../utils/UrlResolver";
import { ApiClient } from "./ApiClient";

export class BriefApiClient extends ApiClient {
    public static key = "BriefApiClient";

    public async CreateBrief(briefDto: BriefDTO, selectedBrief: string, accessToken: string): Promise<any> {
        return await this.Post(resolveUrl(`${this.baseUrl}`, `brief`), { ...briefDto, configUsed: selectedBrief }, accessToken);
    }

    public async AddAllocInfos(briefId: string, sessionId: string, sessionRunId: string, accessToken: string): Promise<any> {
        return await this.Put(resolveUrl(`${this.baseUrl}`, `brief/addAllocationInfos`), { briefId, sessionId, sessionRunId }, accessToken);
    }

    public async GetBrief(briefId: string, accessToken: string): Promise<BriefDTO> {
        return (await this.Get<ApiResponse<BriefDTO>>(resolveUrl(`${this.baseUrl}`, `brief/${briefId}`), accessToken)).data;
    }

    public async GetBriefFromExternalSource(externalId: string, accessToken: string): Promise<BriefDTO> {
        return (await this.Get<ApiResponse<BriefDTO>>(resolveUrl(`${this.baseUrl}`, `brief/fromExternalSource/${externalId}`), accessToken)).data;
    }

    public async SearchBriefs(accessToken: string, searchParams: Partial<BriefDTO>): Promise<BriefDTO[]> {
        return (await this.Post<ApiResponse<BriefDTO[]>>(resolveUrl(`${this.baseUrl}`, `brief/search`), searchParams, accessToken)).data;
    }
}