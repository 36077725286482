import { Brief, BriefDTO, BriefMediaChannel, BriefMediaChannelAllocator, Daypart, EpRepartition, GrpPerPeriod, MediaChannelCompetitionCodes, MediaChannelTimingConstraints } from "../dto/Brief";
import { Status } from "../dto/Status";

function InputTimeToIntTime(inputTime: string): number {
    if (inputTime === "")
        return -1;

    const splitTime = inputTime.split(":");

    return (parseInt(splitTime[0]) * 100 + parseInt(splitTime[1])) * 100;
}

export function ConvertInputDataToBriefDTO(inputData: any, systemID: string, includeIds: boolean = false): BriefDTO {
    const ret = {
        SystemId: systemID,
    } as BriefDTO;

    // General criterias
    let sellingStrategyRate = parseFloat(inputData.sellingStrategyValue);
    let product = inputData.selectedProduct;

    if (product && product.id.startsWith("new-id")) {
        product = {
            id: "-1",
            name: product.name
        }
    }

    const brief = {
        UseAvailableScreens: true,
        StartDate: inputData.startDate,
        EndDate: inputData.endDate,
        IncludedDays: inputData.selectedDays ? inputData.selectedDays : [0, 1, 2, 3, 4, 5, 6],
        Sector: includeIds ? inputData.selectedSector : inputData.selectedSector.name,
        Agency: includeIds ? inputData.selectedAgency : (inputData.selectedAgency ? inputData.selectedAgency.name : ""),
        Advertiser: includeIds ? inputData.selectedAdvertiser : inputData.selectedAdvertiser.name,
        Product: includeIds ? product : product.name,
        Target: includeIds ? inputData.selectedTarget : inputData.selectedTarget.name,
        Budget: inputData.netBudget,
        MediaChannels: [],
        GrpPerFormat: [],
        SellingStrategy: {
            Method: inputData.sellingStrategyType?.id ? inputData.sellingStrategyType.id : "discount",
            Rate: !isNaN(sellingStrategyRate) ? sellingStrategyRate : 0
        },
    } as Brief;

    if (includeIds)
        brief.Currency = inputData.selectedCurrency;

    // Media channel criterias
    for (let i = 0; i < inputData.selectedMediaChannels.length; i++) {
        const mediaChannelName = inputData.selectedMediaChannels[i].name;
        const mediaChannelToPush = includeIds ? {
            Name: inputData.selectedMediaChannels[i],
        } as BriefMediaChannel
        : {
            Name: mediaChannelName,
            Code: inputData.selectedMediaChannels[i].code,
        } as BriefMediaChannelAllocator;

        mediaChannelToPush.Budget = 0;

        inputData.competitionCodes.forEach((competitionCode: MediaChannelCompetitionCodes) => {
            if (inputData.selectedMediaChannels[i].id === competitionCode.mediaChannelId) {
                const sectorId = inputData.selectedSector.id;

                competitionCode.competitionCodeBySectors.forEach((competitionCodeBySector) => {
                    if (competitionCodeBySector.sectorId === sectorId) {
                        mediaChannelToPush.ClashCode = competitionCodeBySector.competitionCode;
                    }
                });
            }
        });

        if (inputData.mediaChannelsTab) {
            for (let j = 0; j < inputData.mediaChannelsTab.length; j++) {
                if (mediaChannelName === inputData.mediaChannelsTab[j].label.value) {
                    const mediaChannelInputData = inputData.mediaChannelsTab[j];
                    mediaChannelToPush.Budget = mediaChannelInputData.budget.value === "" ? 0 : Number.parseFloat(mediaChannelInputData.budget.value);

                    // Dayparts and EPs
                    for (const key in mediaChannelInputData) {
                        if (mediaChannelInputData[key].additionalProperties?.isDayPart) {
                            const dayPartToPush: Daypart = {
                                Daypart: key,
                                Repartition: mediaChannelInputData[key].value === "" ? 0 : Number.parseFloat(mediaChannelInputData[key].value) / 100,
                                TimeRange: mediaChannelInputData[key].additionalProperties.timeRange
                            }

                            if (mediaChannelToPush.Dayparts === undefined && dayPartToPush.Repartition > 0)
                                mediaChannelToPush.Dayparts = [];

                            if (mediaChannelToPush.Dayparts)
                                mediaChannelToPush.Dayparts.push(dayPartToPush);
                        }
                        else if (mediaChannelInputData[key].additionalProperties?.isEP) {
                            const epToPush: EpRepartition = {
                                Ep: key.split("Value")[0],
                                Repartition: mediaChannelInputData[key].value === "" ? 0 : Number.parseFloat(mediaChannelInputData[key].value) / 100,
                                Method: mediaChannelInputData.screenMethodType.value
                            }
                            if (mediaChannelToPush.EpRepartition === undefined && epToPush.Repartition > 0)
                                mediaChannelToPush.EpRepartition = [];

                            if (mediaChannelToPush.EpRepartition)
                                mediaChannelToPush.EpRepartition.push(epToPush);
                        }
                    }

                    // Date and time
                    mediaChannelToPush.NoAllocationBefore = mediaChannelInputData.noAllocationBefore.value === "" ? 0 : InputTimeToIntTime(mediaChannelInputData.noAllocationBefore.value);
                    mediaChannelToPush.NoAllocationAfter = mediaChannelInputData.noAllocationAfter.value === "" ? 999999 : InputTimeToIntTime(mediaChannelInputData.noAllocationAfter.value);
                    mediaChannelToPush.CampaignStartTime = mediaChannelInputData.campaignStartTime.value === "" ? mediaChannelToPush.NoAllocationBefore : InputTimeToIntTime(mediaChannelInputData.campaignStartTime.value);
                    mediaChannelToPush.CampaignEndTime = mediaChannelInputData.campaignEndTime.value === "" ? mediaChannelToPush.NoAllocationAfter : InputTimeToIntTime(mediaChannelInputData.campaignEndTime.value);
                    AddAllocationConstraint("MinAllocationPerDay", mediaChannelInputData.minAllocationPerDay.value, mediaChannelToPush);
                    AddAllocationConstraint("MaxAllocationPerDay", mediaChannelInputData.maxAllocationPerDay.value, mediaChannelToPush);
                    AddAllocationConstraint("AllocationOffsetMinutes", mediaChannelInputData.allocationOffsetMinutes.value, mediaChannelToPush);

                    break;
                }
            }
        }
        else {
            mediaChannelToPush.NoAllocationBefore = 0;
            mediaChannelToPush.NoAllocationAfter = 999999;
            mediaChannelToPush.CampaignStartTime = mediaChannelToPush.NoAllocationBefore;
            mediaChannelToPush.CampaignEndTime = mediaChannelToPush.NoAllocationAfter;
        }
        brief.MediaChannels.push(mediaChannelToPush);
    }

    // Format criterias
    for (let i = 0; i < inputData.grpPerFormat.length; i++) {
        brief.GrpPerFormat.push({ Format: Number.parseInt(inputData.grpPerFormat[i].format), GrpRepartitionPercent: inputData.grpPerFormat[i].grpRepartitionPercent / 100 });
    }

    // If every format has a repartition of 0, we divide 1 by the number of formats
    if (brief.GrpPerFormat.every((grpPerFormat) => grpPerFormat.GrpRepartitionPercent === 0)) {
        brief.GrpPerFormat.forEach((grpPerFormat) => {
            grpPerFormat.GrpRepartitionPercent = 1 / brief.GrpPerFormat.length;
        });
    }

    // Format by period criterias
    if (inputData.grpPerFormatPerPeriod && inputData.grpPerFormatPerPeriod.length > 1) {
        brief.GrpPerPeriod = [];

        for (let i = 0; i < inputData.grpPerFormatPerPeriod.length; i++) {
            const toPush: GrpPerPeriod = {
                StartDate: inputData.grpPerFormatPerPeriod[i].dateStart,
                EndDate: inputData.grpPerFormatPerPeriod[i].dateEnd,
                Repartition: Number.parseFloat(inputData.grpPerFormatPerPeriod[i].grpRepartitionPercent) / 100,
                FormatRepartition: []
            };

            inputData.grpPerFormatPerPeriod[i].formatRepartition.forEach((formatRepartition: any) => {
                toPush.FormatRepartition.push({
                    Format: Number.parseInt(formatRepartition.format),
                    Repartition: Number.parseFloat(formatRepartition.repartition) / 100
                });
            });

            brief.GrpPerPeriod.push(toPush);
        }
    }

    // We need to check check if the budget repartition is 100% of the global budget
    // If not, we allocate the remaining budget equally to media channels that have a budget of 0
    const totalBudget = brief.Budget;
    const mediachannelsInputBudget = brief.MediaChannels.map((mediaChannel) => mediaChannel.Budget).reduce((acc, budget) => acc + budget, 0);
    const remainingBudget = totalBudget - mediachannelsInputBudget;

    if (remainingBudget > 0) {
        const mediaChannelsWithZeroBudget = brief.MediaChannels.filter((mediaChannel) => mediaChannel.Budget === 0);
        const budgetPerMediaChannel = remainingBudget / mediaChannelsWithZeroBudget.length;

        mediaChannelsWithZeroBudget.forEach((mediaChannel) => {
            mediaChannel.Budget = budgetPerMediaChannel;
        });
    }

    ret.brief = brief;
    
    return (ret);
}

function AddAllocationConstraint<K extends keyof MediaChannelTimingConstraints>(key: K, value: string, mediaChannelToPush: BriefMediaChannel) {
    if (value !== "" && value !== "0") {
        if (!mediaChannelToPush.AllocationTimingConstraints)
            mediaChannelToPush.AllocationTimingConstraints = {};

        mediaChannelToPush.AllocationTimingConstraints[key] = Number.parseInt(value);
    }
}

export function ConvertBriefDTOToInputData(briefDTO: BriefDTO): any {
    
}

export function GetBriefStatus(brief: BriefDTO): Status {
    if (brief.allocatorSessionId && brief.allocatorSessionRunId) {
        if (brief.allocatedMediaPlans !== undefined && brief.allocatedMediaPlans > 0) {
            if (brief.selectedMediaPlanIdx !== undefined)
                return { text: "txt_solution_chosen", color: "green" };
            else
                return { text: "txt_solutions_available", color: "blue" };
        }
        else
            return { text: "txt_brief_no_solutions", color: "orange" };
    }
    else
        return { text: "txt_brief_not_sent_to_allocator", color: "red" };
}