import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataKey, ExternalSystemElement } from "../../Business/dto/ExternalSystemElement";
import { DataRetrieval } from "../../Business/dto/ExternalSystemRetrieveBody";
import { ApiClientManager } from "../../Business/api/ApiClientManager";
import { ExternalSystemElementApiClient } from "../../Business/api/ExternalSystemElementApiClient";
import { BriefDTO } from "../../Business/dto/Brief";
import { BriefApiClient } from "../../Business/api/BriefApiClient";

export interface BriefState {
    retrievedData: {
        [key: string]: ExternalSystemElement[];
    },
    inputData: {
        [key: string]: any;
    },
    briefFromExternalSource?: BriefDTO;
    history: BriefDTO[];
}

export const initialState: BriefState = {
    retrievedData: {
        [DataKey.Sector]: [],
        [DataKey.Target]: [],
        [DataKey.MediaChannel]: [],
        [DataKey.DayPart]: [],
        [DataKey.Country]: [],
        [DataKey.Currency]: [],
        [DataKey.Agency]: [],
        [DataKey.Advertiser]: [],
        [DataKey.Product]: [],
        [DataKey.Competition]: []
    },
    inputData: {

    },
    history: []
}

export interface LoadDataPayload {
    dataKey: DataKey;
    body?: DataRetrieval;
}

export const RetrieveDataFromExternalSource = createAsyncThunk<ExternalSystemElement[], LoadDataPayload>(
    'brief/retrieveDataFromExternalSource',
    async (dataRetrievalConfig) => {
        const data: ExternalSystemElement[] = [];
        const retrievedData = await ApiClientManager.getInstance().getApiClient<ExternalSystemElementApiClient>(ExternalSystemElementApiClient.key).RetrieveDataFromExternalSource(dataRetrievalConfig.dataKey, localStorage.getItem('token') || "", dataRetrievalConfig.body);

        if (dataRetrievalConfig.dataKey !== DataKey.Competition && dataRetrievalConfig.dataKey !== DataKey.Media)
            data.push(...retrievedData.sort((a, b) => a.name.localeCompare(b.name)));
        else
            data.push(...retrievedData);

        return (data);
    }
);

export const SaveBrief = createAsyncThunk<string, { brief: BriefDTO, selectedBrief: string }>(
    'brief/saveBrief',
    async (briefData) => {
        const res = await ApiClientManager.getInstance().getApiClient<BriefApiClient>(BriefApiClient.key).CreateBrief(briefData.brief, briefData.selectedBrief, localStorage.getItem('token') || "");
        //res.data.brief._id
        return res.data.brief._id;
    }
);

export const SearchBriefs = createAsyncThunk<BriefDTO[], Partial<BriefDTO>>(
    'brief/searchBriefs',
    async (searchParams) => {
        const res = await ApiClientManager.getInstance().getApiClient<BriefApiClient>(BriefApiClient.key).SearchBriefs(localStorage.getItem('token') || "", searchParams);
        return res;
    }
);

export const GetBriefFromExternalSource = createAsyncThunk<BriefDTO, string>(
    'brief/getBriefFromExternalSource',
    async (externalId) => {
        const res = await ApiClientManager.getInstance().getApiClient<BriefApiClient>(BriefApiClient.key).GetBriefFromExternalSource(externalId, localStorage.getItem('token') || "");
        return res;
    }
);

export const briefSlice = createSlice({
    name: 'brief',
    initialState,
    reducers: {
        setInputValue: (state, action: PayloadAction<{key: string, value: any}>) => {
            state.inputData[action.payload.key] = action.payload.value;
        },
        setRetrievedData: (state, action: PayloadAction<{key: DataKey, value: ExternalSystemElement[]}>) => {
            state.retrievedData[action.payload.key] = action.payload.value;
        },
        resetInputData: (state) => {
            state.inputData = {};
        },
        resetRetrievedData: (state) => {
            state.retrievedData = {
                [DataKey.Sector]: [],
                [DataKey.Target]: [],
                [DataKey.MediaChannel]: [],
                [DataKey.DayPart]: [],
                [DataKey.Country]: [],
                [DataKey.Currency]: [],
                [DataKey.Agency]: [],
                [DataKey.Advertiser]: [],
                [DataKey.Product]: [],
                [DataKey.Competition]: []
            };
        },
        resetBriefFromExternalSource: (state) => {
            state.briefFromExternalSource = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(RetrieveDataFromExternalSource.fulfilled, (state, action) => {
            state.retrievedData[action.meta.arg.dataKey] = action.payload;
        });
        builder.addCase(GetBriefFromExternalSource.fulfilled, (state, action) => {
            state.briefFromExternalSource = action.payload;
        });
        builder.addCase(SearchBriefs.fulfilled, (state, action) => {
            state.history = action.payload;
        });
    }
});

export const { setInputValue, setRetrievedData, resetInputData, resetRetrievedData, resetBriefFromExternalSource } = briefSlice.actions;


export default briefSlice.reducer;