import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { Input } from "../Components/Inputs/Input"
import { DefaultInput } from "../Components/Inputs/DefaultInput"
import { useTranslation } from 'react-i18next';
import { ApiClientManager } from "../Business/api/ApiClientManager";
import { AuthApiClient } from "../Business/api/AuthApiClient";
import { useParams } from "react-router-dom";
import React from "react";
import { toast } from "react-toastify";


export const ForgotPassword = () => {

    const { t } = useTranslation();
    const { clientId } = useParams();
    const [isSuccess, setIsSuccess] = React.useState(false);
    const onFormSubmit = async (event: any) => {
        event.preventDefault();
        try {
            await ApiClientManager.getInstance().getApiClient<AuthApiClient>(AuthApiClient.key).ForgotPassword(event.target[0].value, clientId || "");
            setIsSuccess(true);
            setTimeout(() => {
                window.location.href = `/${clientId}/`;
            }, 5000);
        }
        catch (e) {
            console.error(e);
        }
    }

    React.useEffect(() => {
        if(isSuccess) {
            toast.success(t('txt_forgot_pass_success'));
        }
    // eslint-disable-next-line
    },[isSuccess])


    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="leftSection">
                        <Row className="mb-3">
                            <Col>
                                <img className="homeLogo" src="/img/JAM_ Logo&Slogan.svg" alt="JAM LOGO" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <h1 className="homeTitle">{t('txt_forgot_pass')}</h1>
                            </Col>
                        </Row>
                        <Form onSubmit={onFormSubmit}>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group>
                                        <Input title={t("Email")}>
                                            <DefaultInput type="email" iconKey='email' placeholder={t('Email')} onChange={(_value) => { }} />
                                        </Input>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3 ">
                                <Col>
                                    <Button className="loginButton" type="submit">{t("txt_send")}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col className="d-none d-md-block rightSection">
                        <img className="imgRightSection" src="/img/RightSection.svg" alt="Right Section" />
                    </Col>
                </Row>
            </Container >
        </>
    )

}