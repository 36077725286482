import { Button, Table } from "react-bootstrap";
import "./DataTableV2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { PickerItem } from "./DataTableCellPicker";
import { DataTableRowCellContent } from "./DataTableRowCellContent";
import { useTranslation } from "react-i18next";

export interface DataTableV2DataHeader {
    name: string;
    dataKey: string;
    total?: boolean;
    showHeader: boolean;
}

export interface DataTableV2DataRow {
    [key: string]: DataTableV2DataRowCell;
}

export interface DataTableV2DataRowCell {
    value: string,
    editable: boolean,
    type?: "text" | "number" | "picker" | "date" | "time" | "percentage",
    pickerOptions?: PickerItem[],               // used when type is "picker"
    setStartDate?: (date: number) => void,      // used when type is "date"
    setEndDate?: (date: number) => void,        // used when type is "date"
    maxDate?: number,                           // used when type is "date"
    startDate?: number,                         // used when type is "date"
    endDate?: number,                           // used when type is "date"
    isRowHeader?: boolean,
    additionalProperties?: { [key: string]: any }
}

export interface DataTableV2Props {
    headers: DataTableV2DataHeader[];
    data: DataTableV2DataRow[];
    addLine?: () => void;
    deleteLine?: (rowIdx: number) => void;
    editValue?: (rowIdx: number, key: string, value: string) => void;
    onBlur?: (rowIdx: number) => void;
}

export const DataTableV2 = (props: DataTableV2Props) => {
    const { t } = useTranslation();
    const renderHeader = (header: DataTableV2DataHeader, idx: number) => {
        return (
            <th key={idx}>{header.showHeader ? header.name : ""}</th>
        );
    }

    const onCellEdit = (value: string, rowIdx: number, key: string) => {
        // Get the data cell
        const row = props.data[rowIdx];
        const cell = row[key];

        if (cell.type && cell.type === "percentage") {
            if (value !== "") {
                const numberValue = parseInt(value);

                if (isNaN(numberValue))
                    value = "";
                else if (numberValue < 0)
                    value = "0";
                else if (numberValue > 100)
                    value = "100";

            }
        }


        if (props.editValue !== undefined)
            props.editValue(rowIdx, key, value);
    }

    const renderRowCell = (key: string, idx: number, row: DataTableV2DataRow, rowIdx: number) => {
        const cell = row[key];
        const header = props.headers.find(h => h.dataKey === key)

        if (!header)
            return <></>

        const isLastCellInRow = idx === Object.keys(row).length - 1;
        const isLastCellComparedToHeaders = idx === props.headers.length - 1;

        return (
            <td className={isLastCellInRow && isLastCellComparedToHeaders ? "lastDataCell" : ""} key={idx}>
                <DataTableRowCellContent cell={cell} onChange={(event: any) => onCellEdit(event.target.value, rowIdx, key)} onBlur={() => {props.onBlur && props.onBlur(rowIdx)}}/>
            </td>
        )
    }

    const renderRowTotal = (key: string, idx: number, row: DataTableV2DataRow) => {
        const header = props.headers.find(h => h.dataKey === key)

        let total = 0;
        if (header && header.total) {
            props.data.forEach((row) => {
                total += parseFloat(row[key].value) || 0;
            });
        }

        if (!header)
            return <></>

        return (
            <td key={idx}>
                {header.total ? "Total : " + total : ""}
            </td>
        )
    }

    const renderRow = (row: DataTableV2DataRow, idx: number) => {
        return (
            <tr className="dataRow" key={idx}>
                {props.headers.map((header, dataIdx) => renderRowCell(header.dataKey, dataIdx, row, idx))}
                {
                    // Add empty cells to fill the row
                    props.headers.length > Object.keys(row).length
                        ? new Array(props.headers.length - Object.keys(row).length - ((props.deleteLine !== undefined) ? 1 : 0)).fill(null).map((_, idx) => {
                            const isLast = idx === props.headers.length - Object.keys(row).length - 1;
                            return (
                                <td className={isLast ? "lastDataCell" : ""} key={idx}></td>
                            );
                        })
                        : <></>
                }
                {props.deleteLine !== undefined
                    ? renderDeleteLineButton(idx)
                    : <></>
                }
            </tr>
        );
    }

    const renderDeleteLineButton = (idx: number) => {
        return (
            <td className="cellDeleteButton">
                <Button className="buttonDelete" onClick={() => props.deleteLine ? props.deleteLine(idx) : null}>
                    <FontAwesomeIcon className="buttonDeleteIcon" style={{ color: "red" }} icon={faCircleMinus} />
                </Button>
            </td>
        );
    }

    const renderAddLineButton = () => {
        let colSpan = props.deleteLine ? props.headers.length + 1 : props.headers.length;
        return (
            <tr className="rowAddButton">
                <td colSpan={colSpan}>
                    <Button className="buttonAdd" onClick={props.addLine}>{t("txt_add")}</Button>
                </td>
            </tr>
        );
    }

    return (
        <Table responsive className="dataTableV2">
            <thead>
                <tr className="dataTableV2HeaderRow">
                    {props.headers.map(renderHeader)}
                    {props.deleteLine !== undefined
                        ? <th className="deleteButtonCell"></th>
                        : <></>
                    }
                </tr>
            </thead>
            <tbody>
                {props.data.map(renderRow)}
                <tr className="total">
                    {props.headers.map((header, idx) => renderRowTotal(header.dataKey, idx, props.data[0]))}
                </tr>
                {props.addLine !== undefined
                    ? renderAddLineButton()
                    : <></>
                }
            </tbody>
        </Table>
    );
}