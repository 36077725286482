import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import clientConfigReducer from "./Reducers/clientSlice";
import localeReducer from "./Reducers/localeSlice";
import briefReducer from "./Reducers/briefSlice";
import authReducer from "./Reducers/authSlice";
import userReducer from "./Reducers/userSlice";

export const store = configureStore({
    reducer: {
        clientConfig: clientConfigReducer,
        locale: localeReducer,
        brief: briefReducer,
        auth: authReducer,
        user: userReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;