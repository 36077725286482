import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserDetails } from "../../Business/dto/UserDetails";
import { ApiClientManager } from "../../Business/api/ApiClientManager";
import { UserApiClient } from "../../Business/api/UserApiClient";

export interface UserState {
    details?: UserDetails;
    userList: UserDetails[];
    canAllocate?: boolean;
}

const initialState: UserState = {
    userList: []
}

export const GetUserDetails = createAsyncThunk<UserDetails | undefined, void>(
    'user/getUserDetails',
    async () => {
        const accessToken = localStorage.getItem('token');

        if (!accessToken)
            return undefined;

        return await ApiClientManager.getInstance().getApiClient<UserApiClient>(UserApiClient.key).GetDetails(accessToken);
    }
);

export const GetUserList = createAsyncThunk<UserDetails[], void>(
    'user/getUserList',
    async () => {
        const accessToken = localStorage.getItem('token');

        if (!accessToken)
            return [];

        return await ApiClientManager.getInstance().getApiClient<UserApiClient>(UserApiClient.key).GetUserList(accessToken);
    }
);

export const CanUserAllocate = createAsyncThunk<boolean, string>(
    'user/canUserAllocate',
    async (clientId) => {
        const accessToken = localStorage.getItem('token');

        if (!accessToken)
            return false;

        return await ApiClientManager.getInstance().getApiClient<UserApiClient>(UserApiClient.key).CanAllocate(accessToken, clientId);
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(GetUserDetails.fulfilled, (state, action) => {
            state.details = action.payload;
        });
        builder.addCase(GetUserList.fulfilled, (state, action) => {
            state.userList = action.payload;
        });
        builder.addCase(CanUserAllocate.fulfilled, (state, action) => {
            state.canAllocate = action.payload;
        });
    }
});

export default userSlice.reducer;