import { SectionBody } from "./SectionBody";
import { SectionHeader } from "./SectionHeader";
import "./Section.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { BriefDTO } from "../../Business/dto/Brief";

export interface SectionProps {
    idx: number;
    expanded: boolean;
    onChangeSection: (sectionIdx: number) => void;
    setSectionValidated: (sectionIdx: number, validated: boolean) => void;
    nextButtonDisabled: boolean;
    briefToLoad?: BriefDTO;
}

export abstract class Section<Props extends SectionProps> {
    public Name: string = "";

    public Component: React.FC<Props> = (props: Props) => {
        const { t } = useTranslation();
        return (
            <>
                <SectionHeader onChangeSection={props.onChangeSection} idx={props.idx}>
                    <b>{t(this.Name)}</b>
                </SectionHeader>
                <SectionBody expanded={props.expanded} onChangeSection={props.onChangeSection} idx={props.idx}>
                    {this.SectionBody(props)}
                </SectionBody>
            </>
        );
    }

    protected abstract SectionBody(props: SectionProps): JSX.Element;
};