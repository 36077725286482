import { useTranslation } from "react-i18next";
import { DataTableV2, DataTableV2DataHeader, DataTableV2DataRow } from "../Inputs/DataTableV2"


export interface MediaChannelBudgetTabProps {
    data: DataTableV2DataRow[];
    onCellEdit: (rowIdx: number, key: string, value: string) => void;
}

export const MediaChannelBudgetTab = (props: MediaChannelBudgetTabProps) => {
    const { t } = useTranslation();
    const headers: DataTableV2DataHeader[] = [
        { name: "", dataKey: "label", showHeader: false },
        { name: t("budget"), dataKey: "budget", showHeader: true },
    ]

    return (
        <>
            <DataTableV2
                headers={headers}
                data={props.data}
                editValue={props.onCellEdit}
            />
        </>
    )
}