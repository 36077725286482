import React from "react";
import { MediaChannel } from "../../Business/dto/Brief";
import { DataRetrieval } from "../../Business/dto/ExternalSystemRetrieveBody";
import { ExternalDataPicker } from "./ExternalDataPicker";
import { DataKey } from "../../Business/dto/ExternalSystemElement";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

export interface MediaChannelPickerProps {
    retrieveMediaChannelsUrl: string;
    retrieveMediaChannelsBody: any;
    retrieveMediaChannelsMethod: "get" | "post";
    retrieveMediaChannelsParser: any;
    setSelectedMediaChannels: (mediaChannels: MediaChannel[]) => void;
    disabled?: boolean;
    required?: boolean;
    value: MediaChannel[] | undefined;
}

export const MediaChannelPicker = (props: MediaChannelPickerProps) => {
    const { t } = useTranslation();
    const [body, setBody] = React.useState<DataRetrieval | undefined>(undefined);

    React.useEffect(() => {
        setBody({
            endpoint: props.retrieveMediaChannelsUrl,
            method: props.retrieveMediaChannelsMethod,
            body: props.retrieveMediaChannelsBody,
            parser: props.retrieveMediaChannelsParser
        });
    }, [props.retrieveMediaChannelsBody, props.retrieveMediaChannelsMethod, props.retrieveMediaChannelsParser, props.retrieveMediaChannelsUrl]);

    return (
        <>
            {body &&
                <ExternalDataPicker
                    disabled={props.disabled}
                    setSelectedData={props.setSelectedMediaChannels}
                    placeholder={t("supports")}
                    multiple
                    dataKey={DataKey.MediaChannel}
                    body={body}
                    icon={faNewspaper}
                    controlId="mediaChannelPicker"
                    required={props.required}
                    value={props.value ? props.value : []}
                />
            }
        </>
    );
}