import { ApiResponse } from "../dto/ApiResponse";
import { ClientConfig } from "../dto/ClientConfig";
import { resolveUrl } from "../utils/UrlResolver";
import { ApiClient } from "./ApiClient";

export class ClientApiClient extends ApiClient {
    public static key="ClientApiClient";

    public async GetClientConfig(clientId: string): Promise<ClientConfig> {
        return (await this.Get<ApiResponse<ClientConfig>>(resolveUrl(`${this.baseUrl}`, `/superClient/config/${clientId}`))).data;
    }
}