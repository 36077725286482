import { ApiResponse } from "../dto/ApiResponse";
import { UserDetails } from "../dto/UserDetails";
import { resolveUrl } from "../utils/UrlResolver";
import { ApiClient } from "./ApiClient";

export class UserApiClient extends ApiClient {
    public static key = "User";

    public async GetDetails(accessToken: string): Promise<UserDetails> {
        return (await this.Get<ApiResponse<UserDetails>>(resolveUrl(`${this.baseUrl}`, `/user/details`), accessToken)).data;
    }

    public async GetUserList(accessToken: string): Promise<UserDetails[]> {
        return (await this.Get<ApiResponse<UserDetails[]>>(resolveUrl(`${this.baseUrl}`, `/user/list`), accessToken)).data;
    }

    public async GetSingleUserDetails(accessToken: string, userId: string): Promise<UserDetails> {
        return (await this.Get<ApiResponse<UserDetails>>(resolveUrl(`${this.baseUrl}`, `/user/${userId}`), accessToken)).data;
    }

    public async UpdateUserDetails(accessToken: string, userDetails: UserDetails): Promise<UserDetails> {
        return (await this.Put<ApiResponse<UserDetails>>(resolveUrl(`${this.baseUrl}`, `/user/${userDetails._id}`), userDetails, accessToken)).data;
    }

    public async CanAllocate(accessToken: string, clientId: string): Promise<boolean> {
        return (await this.Post<ApiResponse<any>>(resolveUrl(`${this.baseUrl}`, `/user/canAllocate`), { clientId }, accessToken)).data.canAllocate;
    }
}