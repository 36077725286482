import React from "react";
import { DataRetrieval } from "../../Business/dto/ExternalSystemRetrieveBody";
import { Currency } from "../../Business/dto/Brief";
import { ExternalDataPicker } from "./ExternalDataPicker";
import { DataKey } from "../../Business/dto/ExternalSystemElement";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface CurrencyPickerProps {
    retrieveCurrenciesUrl: string;
    retrieveCurrenciesBody: any;
    retrieveCurrenciesMethod: "get" | "post";
    retrieveCurrenciesParser: any;
    setSelectedCurrency: (currency: Currency) => void;
    required?: boolean;
    value: Currency | undefined;
}

export const CurrencyPicker = (props: CurrencyPickerProps) => {
    const { t } = useTranslation();
    const [body, setBody] = React.useState<DataRetrieval | undefined>(undefined);

    React.useEffect(() => {
        setBody({
            endpoint: props.retrieveCurrenciesUrl,
            method: props.retrieveCurrenciesMethod,
            body: props.retrieveCurrenciesBody,
            parser: props.retrieveCurrenciesParser
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
            {body &&
                <ExternalDataPicker
                    setSelectedData={props.setSelectedCurrency}
                    placeholder={t("txt_currency")}
                    dataKey={DataKey.Currency}
                    body={body}
                    icon={faCoins}
                    controlId="currencyPicker"
                    required={props.required}
                    value={props.value ? [props.value] : []}
                />
            }
        </>
    );
}